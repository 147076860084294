import React, {useState} from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../styles/theme'
import { setLocation } from '../../redux/global'
import { useEffect } from 'react'
import { DefaultDialogTitle, LanguageSelectButton, LanguageSelectList, LocaleListItemButton } from '../../styles/dialog'


const slideTransition = (props) => {
    return <Slide direction='down' {...props} />
}

const defaultTransition = (props) => {
  return <Fade {...props} />
}

const CitySelect = ({ open, onClose}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const [locationSelected, setLocationSelected] = useState(process.env.REACT_APP_DEFAULT_MARKET)
    const location = useSelector((state) => state.persisted.global.location)
    const dispatch = useDispatch()

    useEffect(() => {
        setLocationSelected(location)
    }, [location])

    const onLocationSelect = (value) => {
        setLocationSelected(value)
    }

    const onLocationSubmit = () => {
      dispatch(setLocation(locationSelected))
      onClose()
    }
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="xs">
        <DefaultDialogTitle>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                <FormattedMessage id='modal.city.title' />
                <IconButton sx={{color: Colors.white}} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DefaultDialogTitle>
        <DialogContent>
          <Stack>
            <LanguageSelectList>
              <LocaleListItemButton selected={locationSelected === 'kelowna'} onClick={() => onLocationSelect('kelowna')}>
                <ListItemText primary="Kelowna" />
                { locationSelected === 'kelowna' && <CheckIcon /> }
              </LocaleListItemButton>
              <LocaleListItemButton selected={locationSelected === 'vancouver'} onClick={() => onLocationSelect('vancouver')}>
                <ListItemText primary="Vancouver" />
                { locationSelected === 'vancouver' && <CheckIcon /> }
              </LocaleListItemButton>
            </LanguageSelectList>
            <LanguageSelectButton variant='contained' onClick={onLocationSubmit}>
                <FormattedMessage id='modal.ok' />
            </LanguageSelectButton>
          </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default CitySelect
