import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl'
import { useLoginMutation, useRegisterMutation } from '../../apis/auth'
import { setUser } from "../../redux/global";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'
import { Colors } from "../../styles/theme";
import useBackdrop from "../../hooks/useBackdrop";
import useAlert from "../../hooks/useAlert";
import { SEVERITY_SUCCESS, SEVERITY_ERROR } from "../../constants";
import { Link } from "react-router-dom";
import { useUIContext } from "../../context/ui";


const SignUp = ({onClose}) => {
  const theme = useTheme()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
  
  const [
    register, 
    { isLoading: isUpdating }, 
  ] = useRegisterMutation() 

  const initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    // phone: '',
    password: '',
    passwordConfirmation: '',
  };

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("error.required"),
    lastName: yup.string().required("error.required"),
    username: yup.string().required("error.required"),
    email: yup.string().email("error.invalid.email").required("error.required"),
    // phone: yup
    // .string()
    // .matches(REGEX_PHONE, "error.invalid.phone")
    // .required("error.required"),
    password: yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'error.password.match'),
  });

  const handleFormSubmit = async (values) => {
    try {
      const submitObj = {
          firstName: values.firstName,
          lastName: values.lastName,
          username: values.username,
          email: values.email,
          password: values.password,
          client: process.env.REACT_APP_CURRENT_CLIENT,
          // vendor: process.env.REACT_APP_DEFAULT_VENDOR,
      }
      // console.log('submitObj', submitObj)
      setShowBackdrop(true)
      const result = await register(submitObj)
      if (result.error) {
        setShowBackdrop(false)
        onClose()
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_ERROR,
          content: intl.formatMessage({id: 'auth.register.failed'}),
          duration: null
        })
      } else {
        setShowBackdrop(false)
        onClose()
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: intl.formatMessage({id: 'auth.register.success'}),
          duration: null
        })
      }
    } catch (err) {
      setShowBackdrop(false)
        onClose()
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_ERROR,
          content: intl.formatMessage({id: 'auth.register.failed'}),
          duration: null
        })
    }
    
  };

  return (
    <Stack
        width="100%"
        mt={5}
        // p={3}
        alignItems="flex-start"
      >
        <Typography
          variant="h4"
          color={Colors.green_800}
          fontWeight="bold"
          sx={{ mb: 2, textTransform: 'uppercase' }}
        >
          <FormattedMessage id='app.register' />
        </Typography>
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.first.name'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            name="firstName"
                            error={!!touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName &&  intl.formatMessage({id: errors.firstName})}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.last.name'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            name="lastName"
                            error={!!touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName &&  intl.formatMessage({id: errors.lastName})}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.username'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username &&  intl.formatMessage({id: errors.username})}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.email.address'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email &&  intl.formatMessage({id: errors.email})}
                            sx={{mb: 3}}
                        />
                        {/* <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.phone'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={!!touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone &&  intl.formatMessage({id: errors.phone})}
                            sx={{mb: 3}}
                        /> */}
                        <TextField
                            fullWidth
                            variant="standard"
                            type="password"
                            label={`${intl.formatMessage({id: 'auth.password'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password && intl.formatMessage({id: errors.password})}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="password"
                            label={`${intl.formatMessage({id: 'auth.password.confirmation'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.passwordConfirmation}
                            name="passwordConfirmation"
                            error={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                            helperText={touched.passwordConfirmation && errors.passwordConfirmation && intl.formatMessage({id: errors.passwordConfirmation})}
                            sx={{mb: 3}}
                        />  
                        <Typography variant='p' sx={{mb: 3}}><FormattedMessage id='auth.accept' /><Link to=''><FormattedMessage id='footer.terms.conditions' /></Link><FormattedMessage id='general.and' /><Link to=''><FormattedMessage id='footer.privacy.policy' /></Link></Typography>
                        <Button fullWidth
                            disabled={!!values.firstName === false || 
                                !!values.lastName === false || 
                                !!values.username === false || 
                                !!values.email === false || 
                                !!values.password === false || 
                                !!values.passwordConfirmation === false || 
                                !!errors.firstName  || 
                                !!errors.lastName  || 
                                !!errors.username  || 
                                !!errors.email  || 
                                !!errors.password || 
                                !!errors.passwordConfirmation || 
                                isUpdating }
                            sx={{
                                backgroundColor: Colors.primary,
                                color: Colors.white,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                '&:hover': {
                                    backgroundColor: Colors.green_600,
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit"><FormattedMessage id='app.register' /></Button>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
      </Stack>
  )
}

export default SignUp