import { api } from './api'

const productApi = api.injectEndpoints({
    endpoints: (build) => ({
      getProducts: build.query({
        query: ({ page, pageSize, sort, search, vendor, location, category, tags }) => ({
          url: "api/products",
          method: "GET",
          params: { page, pageSize, sort, search, vendor, location, category, tags },
          // headers: { token: `Bearer ${token}` },
        }),
        providesTags: (result) =>
          result?.products
          ? [
              ...result.products.map(({ _id }) => ({ type: 'Products', id: _id })),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
      }),
      getSoldoutProducts: build.query({
        query: ({ page, pageSize, vendor, location }) => ({
          url: "api/productsSoldout",
          method: "GET",
          params: { page, pageSize, vendor, location },
          // headers: { token: `Bearer ${token}` },
        }),
      }),
    }),
    overrideExisting: false,
})

export const { useGetProductsQuery, useGetSoldoutProductsQuery } = productApi