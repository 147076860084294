import { styled } from "@mui/material/styles";
import { Box, Typography, List, Button, ListItemButton, DialogTitle } from "@mui/material";
import { Colors } from "../theme";
import { green } from "@mui/material/colors";

// Language Select Container
export const LanguageSelectContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    backgroundColor: '#DDD',
}))

export const DefaultDialogTitle = styled(DialogTitle)(() => ({
    background: Colors.green_700,
    color: Colors.white
}))

export const DefaultDialogHead = styled(Typography)(() => ({
    color: Colors.green_700,
    marginBottom: '15px',
    textTransform: 'uppercase',
    textAlign: 'center',
}))

export const DefaultDialogDetails = styled(Typography)(() => ({
    marginBottom: '15px',
    fontSize: '18px',
}))

export const DefaultDialogButton = styled(Button)(() => ({
    marginTop: '20px',
    width: '150px',
    backgroundColor: Colors.green_700,
    color: 'white',
    '&:hover': {
        backgroundColor: Colors.green_600,
    }
}))

export const LanguageSelectList = styled(List)(() => ({
    width: '100%',
    overflow: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
    marginTop: '20px',
    border: `1px solid ${Colors.border}`,
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: Colors.green_50,
        '&, & .MuiListItemIcon-root': {
        color: Colors.green_800,
        },
    },
}))

export const LocaleListItemButton = styled(ListItemButton)(() => ({
    cursor: 'pointer',
    color: Colors.green_900,
}))

export const LanguageSelectButton = styled(Button)(() => ({
    marginTop: '20px',
    backgroundColor: Colors.green_700,
    color: 'white',
    '&:hover': {
        backgroundColor: Colors.green_600,
    }
}))