export const SLIDES = [
    {
        id: 1,
        image: '/assets/images/003.jpeg',
        title: 'slider.title.01',
        description: 'slider.description.01',
        url: '/products',
        state: {
            tag: 'new'
        },
        layout: 'left-right',
    }, 
    {
        id: 2,
        image: '/assets/images/007.jpeg',
        title: 'slider.title.02',
        description: 'slider.description.02',
        url: '/products',
        state: {
            tag: 'organic'
        },
        layout: 'right-left',
    }, 
    // {
    //     id: 3,
    //     image: '/assets/images/010.jpeg',
    //     title: 'slider.title.03',
    //     description: 'slider.description.03',
    //     url: '/products',
    //     layout: 'left-right',
    // }, 
]