export const MENU = [
    {
        id: 1,
        title: 'app.menu.home',
        url: '/',
    }, 
    {
        id: 2,
        title: 'app.menu.new',
        url: '/products',
        state: {
            tag: 'new'
        }
    }, 
    // {
    //     id: 3,
    //     title: 'app.menu.special',
    //     url: '/products',
    //     state: {
    //         tag: 'special'
    //     }
    // }, 
    {
        id: 4,
        title: 'app.menu.vegetables',
        url: '/products',
        state: {
            category: 'vegetables'
        }
    }, 
    {
        id: 5,
        title: 'app.menu.fruits',
        url: '/products',
        state: {
            category: 'fruits'
        }
    },
    {
        id: 6,
        title: 'app.menu.poultry',
        url: '/products',
        state: {
            category: 'eggs'
        }
    },
    {
        id: 7,
        title: 'app.menu.search',
        url: 'search',
    }
]