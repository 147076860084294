import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";


export const SearchBoxContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: Colors.green_900,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 9999,
    opacity: 0.9,
}))

export const SearchField = styled(TextField)(({ theme })=> ({
    '.MuiInputLabel-root': {
        color: Colors.white,
    },
    '.MuiInput-root': {
        fontSize: '1rem',
        color: Colors.white,
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    },
    '.MuiInput-root::before': {
        borderBottom: `1px solid ${Colors.white}`
    },
    padding: '0px 0 0 40px',
}))