import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { styled } from "@mui/material/styles";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/bars/navbar/Navbar';
import Menubar from '../../components/bars/menubar/Menubar';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../styles/theme';
import { TypographyAdobe } from '../../components/TypographyAdobe';
import IncDec from '../../ui';
import { INCDEC_SIZE_SMALL } from '../../constants';
import { addProductToCart, removeProductFromCart } from '../../redux/global';

export const ImageCovered = styled('img')(({}) => ({
    objectFit: 'cover',
}))


const CartItem = ({cartProduct, product, soldouts}) => {
    const dispatch = useDispatch()
    const location = useSelector((state) => state.persisted.global.location) || process.env.REACT_APP_DEFAULT_MARKET
    const locale = useSelector((state) => state.persisted.global.locale)
    const [quantity, setQuantity] = useState(cartProduct?.quantity)
    const [productId, setProductId] = useState(null)
    // const [productName, setProductName] = useState('')
    useEffect(() => {
        if (cartProduct) {
            setProductId(cartProduct?._id?.indexOf('-') > 0 ? cartProduct?._id?.split('-')[0] : cartProduct?._id)
        }
    }, [cartProduct])

    // console.log('cartProduct', product, cartProduct)
    useEffect(() => {
        const saleInfoIdx = product?.saleInfo?.findIndex((info) => info.level?.length === 0 || JSON.stringify(info.level) === JSON.stringify(cartProduct.level))
        // console.log('saleInfoIdx', product, cartProduct)
        if (quantity > cartProduct?.quantity) {
            dispatch(addProductToCart({
                product: product,
                saleInfoIdx: saleInfoIdx,
                quantity: (quantity - cartProduct?.quantity),
            }))
        } else if (quantity < cartProduct?.quantity) {
            dispatch(removeProductFromCart({
                product: product,
                saleInfoIdx: saleInfoIdx,
                quantity: (cartProduct?.quantity - quantity),
            }))
        }
    }, [quantity])

    const isSoldout = useMemo(() => {
        return soldouts.findIndex((item) => item._id === cartProduct._id) >= 0 
    }, [cartProduct, soldouts])
    // console.log('cartProduct', cartProduct)
    // console.log('soldouts', soldouts)
  return (
    <>
        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' p={3} borderBottom={`1px solid ${Colors.gray_300}`}>
            <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                <ImageCovered src={process.env.REACT_APP_S3_BUCKET_URL + cartProduct?.image} width="120px" height="120px" sx={{border: `5px solid ${Colors.green_200}`, borderRadius: '10px', mr:2}} />
                <Stack sx={{}}>
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <TypographyAdobe variant='body' sx={{mr: 1, color: Colors.green_900 }}><FormattedMessage id='product.name' />: </TypographyAdobe>
                        <TypographyAdobe variant='body'>{product?.name[locale]}</TypographyAdobe>
                    </Box>
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <TypographyAdobe variant='body' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='product.id' />: </TypographyAdobe>
                        <TypographyAdobe variant='body'>{productId}</TypographyAdobe>
                    </Box>
                    {cartProduct?.level && <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <TypographyAdobe variant='body' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.grade' />: </TypographyAdobe>
                        <TypographyAdobe variant='body'>{cartProduct?.level ? cartProduct?.level[locale] : ''}</TypographyAdobe>
                    </Box>}
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <TypographyAdobe variant='body' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.unit' />: </TypographyAdobe>
                        <TypographyAdobe variant='body'>{cartProduct?.unit ? cartProduct?.unit[locale] : ''}</TypographyAdobe>
                    </Box>
                    {isSoldout && (
                        <Box display='flex' justifyContent='flex-start' alignItems='center'>
                            <Typography variant='p' sx={{mr: 1, color: Colors.danger}}><FormattedMessage id='commercial.sold.out' /> </Typography>
                        </Box>
                    )}
                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                        <IncDec value={quantity} setValue={setQuantity} size={INCDEC_SIZE_SMALL} />
                        <TypographyAdobe variant='h5' ml={3} sx={{color: Colors.green_800}}>${cartProduct.subtotal.toFixed(2)}</TypographyAdobe>
                    </Box>
                </Stack>
            </Box>
        </Box>
    </>
  )
}

export default CartItem