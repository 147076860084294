import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  ListItemText,
  Typography,
  List,
  Stack,
  Button,
  ListItemButton,
  Link,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";
import { FooterCertImage, FooterTitle, SubscribeTf } from "../../styles/footer";
import { Colors } from "../../styles/theme";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useDialogModal from "../../hooks/useDialogModal";
import ContactUs from "../modals/ContactUs";
import { CONTACT } from "../../data/contact";
import Login from "../modals/Login";
import { useNavigate } from "react-router-dom";
import { SEVERITY_SUCCESS } from "../../constants";
import useAlert from "../../hooks/useAlert";

const Footer = ({ mobile, tablet }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.persisted.global.currentUser);
  const [ContactUsDialog, showContactUsDialog, closeContactUsDialog] =
    useDialogModal(ContactUs);
  const [LoginDialog, showLoginDialog, closeLoginDialog] =
    useDialogModal(Login);
  const [AlertComponent, showAlert, closeAlert] = useAlert();
  const [alertObj, setAlertObj] = useState({
    show: false,
    severity: SEVERITY_SUCCESS,
    content: "",
  });

  useEffect(() => {
    if (alertObj.show) {
      showAlert();
      setAlertObj((prev) => ({
        ...prev,
        show: false,
      }));
    }
  }, [alertObj.show]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      <Stack
        sx={{
          background: Colors.shaft,
          color: Colors.white,
          p: { xs: 4, md: 10 },
          pt: 12,
          pb: 12,
          fontSize: { xs: "12px", md: "14px" },
        }}
      >
        <Box
            width='100%'
          display="flex"
          flexDirection={mobile ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          <Box flex={1} boxSizing='border-box' height='290px' mt={2} mr={4}>
            <Stack>
              <FooterTitle variant="body1">
                <FormattedMessage id="footer.about.us" />
              </FooterTitle>
              <FooterCertImage
                src="/assets/images/cropped-cert002.jpeg"
                alt="Hoest Fresh Produce"
              />
              <Typography variant="p" sx={{ fontSize: "16px" }}>
              (778) 583-9888
              </Typography>
              <Typography variant="p" sx={{ fontSize: "16px" }}>
              2485 LONGHILL RD, KELOWNA, BC.
              </Typography>
              {/* <Box
                sx={{
                  mt: 4,
                  color: Colors.dove_gray,
                }}
              >
                <FacebookIcon
                  sx={{
                    mr: 3,
                    fontSize: "2.2em",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: Colors.gray_100,
                      outline: `2px solid ${Colors.gray_100}`,
                    },
                  }}
                />
                <TwitterIcon
                  sx={{
                    mr: 3,
                    fontSize: "2.2em",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: Colors.gray_100,
                      outline: `2px solid ${Colors.gray_100}`,
                    },
                  }}
                />
                <InstagramIcon
                  sx={{
                    fontSize: "2.2em",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: Colors.gray_100,
                      outline: `2px solid ${Colors.gray_100}`,
                    },
                  }}
                />
              </Box> */}
            </Stack>
          </Box>
          <Box flex={1} boxSizing='border-box'  height='290px' mt={2} mr={4}>
            <Stack>
                <FooterTitle variant='body1'>
                    <FormattedMessage id='footer.certified.organic' />
                </FooterTitle>
                <FooterCertImage src='/assets/images/cert001-300x300.jpeg' alt='Hoest Fresh Produce' sx={{width: '90px'}} />
                <Typography variant='p' sx={{fontSize: '16px'}}>
                <FormattedMessage id='footer.certified.organic.more' />
                </Typography>
            </Stack>
          </Box>
          <Box flex={1} display={(mobile || tablet) ? 'none' : 'block'}  height='290px' mt={2}>
            <FooterTitle variant='body1'>
                <FormattedMessage id='footer.important.links' />
            </FooterTitle>
            <List sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    '&, & .MuiListItemButton-root': {
                        fontSize: '14px',
                    },
                }}>
                <ListItemButton onClick={() => {
                    if (user) {
                        navigate('/profile')
                    } else {
                        showLoginDialog()
                    }
                }}>
                    <Typography lineHeight={2} variant='caption2'>
                        {user ? (
                            <FormattedMessage id='footer.account' />
                        ) : (<FormattedMessage id='app.login' />)}
                    </Typography>
                </ListItemButton>
                <ListItemButton onClick={() => navigate('/cart')}>
                    <Typography lineHeight={2} variant='caption2'>
                    <FormattedMessage id='footer.cart' />
                    </Typography>
                </ListItemButton>
                <ListItemButton onClick={() => openInNewTab(process.env.REACT_APP_TERMS_CONDITIONS_URL)}>
                    <Typography lineHeight={2} variant='caption2'>
                        <FormattedMessage id='footer.privacy.policy' />
                    </Typography>
                </ListItemButton>
                <ListItemButton onClick={() => openInNewTab(process.env.REACT_APP_PRIVACY_POLICY_URL)}>
                    <Typography lineHeight={2} variant='caption2'>
                    <FormattedMessage id='footer.terms.conditions' />
                    </Typography>
                </ListItemButton>
                
                <ListItemButton onClick={() => showContactUsDialog()}>
                    <Typography lineHeight={2} variant='caption2'>
                    <FormattedMessage id='app.contact' />
                    </Typography>
                </ListItemButton>
            </List>
          </Box>
        </Box>
        <Stack
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          {/* <Typography
            variant="h6"
            sx={{ textAlign: 'center', color: Colors.white }}
          >
            FIND US: &nbsp;2485 LONGHILL RD, KELOWNA, BC.
          </Typography>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', color: Colors.white }}
          >
            (778) 583-9888
          </Typography> */}
          <Typography
            variant="h6"
            sx={{ mt: 3, textAlign: 'center', color: Colors.warning }}
          >
            Copyright © 2023 Honest Fresh Produce Inc. All rights reserved.
          </Typography>
        </Stack>
      </Stack>
      <LoginDialog mobile={mobile} tablet={tablet} setAlertObj={setAlertObj} />
      <ContactUsDialog phone={CONTACT.phone} email={CONTACT.email} />
    </>
  );
};

export default Footer;
