import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FlexBetween = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const FlexStart = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
}));