import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProductMetaPriceWrapper, ProductMetaWrapper } from '../../styles/products'
import { Colors } from '../../styles/theme'
import { useSelector } from 'react-redux'

const ProductMeta = ({ product, matches }) => {
  const locale = useSelector((state) => state.persisted.global.locale)
  // const [productName, setProductName] = useState('')
  // useEffect(() => {
  //   if (product) {
  //     setProductName(JSON.parse(product.name))
  //   }
  // }, [product])
  // console.log('productName', productName)
  return (
    <ProductMetaWrapper>
      <Box display='flex' justifyContent='center'>
      <Typography variant={matches ? 'h6' : 'h5'} 
            lineHeight={2}>
                {/* <FormattedMessage id={product.name} /> */}
                {/* {productName && productName[locale]} */}
                {`${product?.name ? product?.name[locale] : ''}`}
        </Typography>
        {product?.level && <Typography variant={matches ? 'h6' : 'h5'} 
            lineHeight={2}>
                ({product?.level ? product?.level[locale] : ''})
        </Typography>}
      </Box>
        
        
        {product.sku === 0 ? (<Typography variant={matches ? 'caption' : 'body1'} sx={{fontSize: '1.5em', color: Colors.danger}}>
          <FormattedMessage id='commercial.sold.out' />
        </Typography>) : 
          (product.special < product.price ? (
            <ProductMetaPriceWrapper>
              <Typography variant={matches ? 'caption' : 'body1'} sx={{
                textDecoration: 'line-through',
                color: Colors.danger,
                marginRight: '15px',
              }}>
                  ${product.price.toFixed(2)}
              </Typography>
              {product.special < product.price && <Typography variant='p' sx={{fontSize: '1.5em'}}>${product.special.toFixed(2)}</Typography>}
            </ProductMetaPriceWrapper>) : (
            <Typography variant={matches ? 'caption' : 'body1'} sx={{fontSize: '1.5em'}}>
                ${product.price.toFixed(2)}
            </Typography>
          ) )
        }
        
    </ProductMetaWrapper>
  )
}

export default ProductMeta;
