import { createSlice } from "@reduxjs/toolkit";
import { LOCALES } from "../i18n/locales";

const initialState = {
  locale: LOCALES.ENGLISH,
  currentUser: null,
  location: process.env.REACT_APP_DEFAULT_MARKET,
  cart: {
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    quantity: 0,
    products: []
  },
  unpaidOrder: {
    id: '',
    user: '',
    vendor: '',
    shipping: 'pickup',
    address: '',
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    products: [],
  }
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLocale: (state, action) => {
        state.locale = action.payload
    },
    setUser: (state, action) => {
        state.currentUser = action.payload
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setCart: (state, action) => {
      state.cart = action.payload
    },
    addProductToCart: (state, action) => {
      let product = {...action.payload.product}
      // console.log('product', product)
      const saleInfo = action.payload.product.saleInfo[action.payload.saleInfoIdx]
      const quantity = action.payload.quantity
      const taxRate = product.category.tax || 0
      const price = saleInfo.special > 0 ? saleInfo.special : saleInfo.price
      const subtotal = price * quantity
      const tax = subtotal * taxRate
      const total = subtotal + tax
      const discount = saleInfo.special > 0 ? (saleInfo.price - saleInfo.special) * quantity : 0
      
      const cartProductId = product._id + '-' + saleInfo._id
      const foundIdx = state.cart.products.findIndex((item) => item._id === cartProductId)

      const productObj = foundIdx >= 0 ? {
        ...state.cart.products[foundIdx],
        quantity: state.cart.products[foundIdx].quantity + quantity,
        subtotal: state.cart.products[foundIdx].subtotal + subtotal
      } : {
        _id: cartProductId ,
        image: product.images[0],
        name: product.name,
        price: saleInfo.price,
        special: saleInfo.special,
        level: saleInfo.level,
        unit: saleInfo.unit,
        quantity,
        subtotal,
      }
      state.cart = {...state.cart, 
        subtotal: state.cart.subtotal + subtotal,
        quantity: state.cart.quantity + quantity,
        tax: state.cart.tax + tax,
        discount: state.cart.discount + discount,
        total: state.cart.total + total,
        products: foundIdx >= 0 ? [...state.cart.products.slice(0, foundIdx), productObj, ...state.cart.products.slice(foundIdx+1, (state.cart.products.length))] : [...state.cart.products, productObj]
      }
    },
    removeProductFromCart: (state, action) => {
      let product = {...action.payload.product}
      const saleInfo = action.payload.product.saleInfo[action.payload.saleInfoIdx]
      const quantity = action.payload.quantity
      const taxRate = product.category.tax || 0
      const price = saleInfo.special > 0 ? saleInfo.special : saleInfo.price
      const subtotal = price * quantity
      const tax = subtotal * taxRate
      const total = subtotal + tax
      const discount = saleInfo.special > 0 ? (saleInfo.price - saleInfo.special) * quantity : 0

      const cartProductId = product._id + '-' + saleInfo._id
      const foundIdx = state.cart.products.findIndex((item) => item._id === cartProductId)
      if (foundIdx >= 0) {
        const productObj = {
          ...state.cart.products[foundIdx],
          quantity: state.cart.products[foundIdx].quantity - quantity,
          subtotal: state.cart.products[foundIdx].subtotal - subtotal
        }

        state.cart = {...state.cart, 
          subtotal: state.cart.subtotal - subtotal,
          quantity: state.cart.quantity - quantity,
          tax: state.cart.tax - tax,
          discount: state.cart.discount - discount,
          total: state.cart.total - total,
          products: productObj.quantity === 0 ? [...state.cart.products.slice(0, foundIdx), ...state.cart.products.slice(foundIdx+1, (state.cart.products.length))] : [...state.cart.products.slice(0, foundIdx), productObj, ...state.cart.products.slice(foundIdx+1, (state.cart.products.length))]
        }
      }
    },
    setUnpaidOrder: (state, action) => {
      state.unpaidOrder = action.payload
    },
  },
});

export const { setLocale, setUser, setLocation, setCart, addProductToCart, removeProductFromCart, setUnpaidOrder } = globalSlice.actions;

export default globalSlice.reducer;
