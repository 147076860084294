import React, {useState, useEffect} from 'react'
import { Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import SearchIcon from '@mui/icons-material/Search';
import { FormattedMessage, useIntl } from 'react-intl'
import { useUIContext } from '../../../context/ui'
import { Colors } from '../../../styles/theme'
import { lighten } from 'polished'
import { useDispatch, useSelector } from 'react-redux';
import { TITLES } from '../../../i18n/titles'
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_HOME, URL_PRODUCTS, URL_LOGIN, URL_CONTACT, URL_PICKUP } from '../../../constants'
import { MENU } from '../../../data/menu'
import { DrawerCloseButton, DrawerItemButton, DrawerList, DrawerTopBar, DrawerTopBarLeftButton } from '../../../styles/bars';
import useDialogModal from '../../../hooks/useDialogModal';
import ContactUs from '../../modals/ContactUs';
import { CONTACT } from '../../../data/contact';
import { setUser } from "../../../redux/global";
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const MiddleDivider = styled((props) => (
    <Divider variant='middle' {...props} />
))``

const NavbarDrawer = ({showLanguageSelectDialog, showPickupDialog, showLoginDialog, user}) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const location = useLocation()
    const dispatch = useDispatch()
    const { drawerOpen, setDrawerOpen, setShowSearchBox } = useUIContext()
    const [ContactUsDialog, showContactUsDialog, closeContactUsDialog] = useDialogModal(ContactUs)
    const locale = useSelector((state) => state.persisted.global.locale)
    const [langTitle, setLangTitle] = useState('English')

    const [activeLink, setActiveLink] = useState(location?.pathname)
    const [userExpanded, setUserExpanded] = React.useState(false);

    const handleUsernameClick = () => {
        setUserExpanded(!userExpanded);
    };

    const handleMenuItem = (item) => {
        setActiveLink(item.url)
        setDrawerOpen(false)
        if (item.url.indexOf('search') >= 0) {
            setShowSearchBox(true)
        } else if (item.state) {
            setTimeout(() => {
                navigate(item.url, {state: item.state})
            }, 100)
        } else {
            setTimeout(() => {
                navigate(item.url)
            }, 100)
        }
    }

    const handleLinkClick = (url) => {
        setActiveLink(url)
        setDrawerOpen(false)
        if (url.indexOf('contact') >= 0) {
            showContactUsDialog()
        } else if (url.indexOf('pickup') >= 0) {
            showPickupDialog()
        } else if (url.indexOf('login') >= 0) {
            showLoginDialog()
        }
    }

    const handleLogin = () => {
        setDrawerOpen(false)
        setTimeout(() => {
            navigate('/login')
        }, 100)
    }

    const handleProfile = () => {
        setDrawerOpen(false)
        setTimeout(() => {
            navigate('/profile')
        }, 100)
    }

    const handleOrders = () => {
        setDrawerOpen(false)
        setTimeout(() => {
            navigate('/orders')
        }, 100)
    }

    const handlePassword = () => {
        setDrawerOpen(false)
        setTimeout(() => {
            // history.push('/profile')
            navigate('/password')
        }, 100)
    }

    const handleLogout = () => {
        if (window.confirm(intl.formatMessage({id: 'window.sure.logout'})) === true) {
            setDrawerOpen(false)
            dispatch(setUser(null))
            navigate('/')
        }
    }

    useEffect(() => {
        setLangTitle(TITLES[locale])
    }, [locale])

    return (
      <>
        <Drawer open={drawerOpen} sx={{height: '100vh'}} onClose={() => setDrawerOpen(false)}>
                <DrawerTopBar>
                    <DrawerTopBarLeftButton selected={activeLink === URL_PICKUP} onClick={() => handleLinkClick(URL_PICKUP)}>
                        <FormattedMessage id='app.pickup' />
                    </DrawerTopBarLeftButton>
                    {/* <DrawerTopBarLeftButton onClick={() => {
                        setDrawerOpen(false)
                        showLanguageSelectDialog()
                    }}>
                        <PublicSharpIcon sx={{marginRight: '10px'}} />
                        <Typography variant='p'>{langTitle}</Typography>
                    </DrawerTopBarLeftButton> */}
                    <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon sx={{
                            fontSize: '0.8em',
                            color: lighten(0.09, Colors.green_800)
                        }} />
                    </DrawerCloseButton>
                </DrawerTopBar>
                <DrawerList>
                    
                    { MENU.map((item) => (
                        <DrawerItemButton key={item.id} onClick={() => handleMenuItem(item)}>
                            { item.title.indexOf('search') > 0 && <SearchIcon sx={{marginRight: '5px'}} />}
                            <FormattedMessage id={item.title} />
                        </DrawerItemButton>
                    ))}
                    <MiddleDivider />
                    {!user && (<DrawerItemButton selected={activeLink === URL_LOGIN} onClick={() => handleLinkClick(URL_LOGIN)}>
                        <FormattedMessage id='app.login' />
                    </DrawerItemButton>)}
                    {user && (
                    <>
                    <DrawerItemButton selected={activeLink === URL_LOGIN} onClick={() => handleUsernameClick()}>
                        <ListItemText>
                        <FormattedMessage id='general.hello' />, {user.username}
                        </ListItemText>
                        {userExpanded ? <ExpandLess /> : <ExpandMore />}
                    </DrawerItemButton>
                    <Collapse in={userExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleProfile()}>
                            <ListItemText><FormattedMessage id='auth.profile' /></ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handlePassword()}>
                            <ListItemText><FormattedMessage id='auth.change.password' /></ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleOrders()}>
                            <ListItemText><FormattedMessage id='auth.orders' /></ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLogout()}>
                            <ListItemText><FormattedMessage id='auth.logout' /></ListItemText>
                        </ListItemButton>
                        </List>
                    </Collapse>
                    </>
                    )}
                    {/* <DrawerItemButton selected={activeLink === URL_PICKUP} onClick={() => handleLinkClick(URL_PICKUP)}>
                        <FormattedMessage id='app.pickup' />
                    </DrawerItemButton> */}
                    <DrawerItemButton selected={activeLink === URL_CONTACT} onClick={() => handleLinkClick(URL_CONTACT)}>
                        <FormattedMessage id='app.contact' />
                    </DrawerItemButton>
                </DrawerList>
            </Drawer>
          <ContactUsDialog />
      </>
    )
}

export default NavbarDrawer