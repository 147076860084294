export const PARTNERS = [
    {
        id: 1,
        title: 'Urban Fare',
        logo: 'https://storage.googleapis.com/images-sof-prd-9fa6b8b.sof.prd.v8.commerce.mi9cloud.com/ufa/favicons/android-icon-192x192.png',
        url: 'https://www.urbanfare.com',
    },
    {
        id: 2,
        title: 'Kelowna Farmers and Crafters Market',
        logo: `https://www.kelownafarmersandcraftersmarket.com/files/Kelowna-Farmer's-and-Crafter's-Market-Logo-1.png`,
        url: 'https://www.kelownafarmersandcraftersmarket.com',
    },
    {
        id: 3,
        title: 'Manteo Eldorado Resort',
        logo: 'https://image-tc.galaxy.tf/wipng-529sp6nq36v26cn1rd97y3tvg/er-manteo-white-transparent.png?width=500',
        url: 'https://www.manteo.com/',
    },
    {
        id: 4,
        title: 'Sungiven Foods',
        logo: 'https://media.licdn.com/dms/image/C510BAQGWh13fgPF6Qw/company-logo_200_200/0/1531909612154?e=2147483647&v=beta&t=TgshKQaG_SyeKn3by9D_xlNbonexwVnnaNX9jYRQA1A',
        url: 'http://www.sungivenfoods.com/',
    },
    {
        id: 5,
        title: 'Hotel Eldorado',
        logo: 'https://assets.hospitalityonline.com/brands/employers/logos/000/266/517/logo.png?1488912394',
        url: 'https://www.hoteleldoradokelowna.com/',
    },
    {
        id: 6,
        title: 'BIRTOO',
        logo: 'https://test-birtoo.s3.ca-central-1.amazonaws.com/logo-001.jpg',
        url: 'https://birtoo.com/',
    }
]