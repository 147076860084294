import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Container, Grid, useMediaQuery } from '@mui/material'
// import { products } from '../../data/products'
import SingleProductMobile from './SingleProductMobile'
import SingleProductDesktop from './SingleProductDesktop'

const Products = ({products, tag, special, category, limit}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const [filteredProducts, setFilteredProducts] = useState([])
    
    useEffect(() => {
        if (products) {
            if (tag === 'new') {
                setFilteredProducts(products?.filter(product => ((tag && product.tags.indexOf(tag) >= 0))))
            } else if (special) {
                setFilteredProducts(products?.filter(product => ((special && product.special < product.price))))
            } else if (category) {
                
                setFilteredProducts(products?.filter(product => {
                    return (JSON.stringify(product.category.title).toLowerCase().indexOf(category.toLowerCase()) >= 0)
                    
            }))
            
            } else if (tag) {
                setFilteredProducts(products?.filter(product => ((JSON.stringify(product.name).toLowerCase().indexOf(tag.toLowerCase()) >= 0 || JSON.stringify(product.description).toLowerCase().indexOf(tag.toLowerCase()) >= 0 || product.tags.toLowerCase().indexOf(tag.toLowerCase()) >= 0 || JSON.stringify(product.category.title).toLowerCase().indexOf(tag.toLowerCase()) >= 0))))
            }
        } 
    }, [products, tag, special, category])

    // console.log('filteredProducts', products)
    
    const renderProducts = useMemo(() => {
        return (filteredProducts?.length > 0) ? 
            [...filteredProducts?.map((product, idx) => (
                idx < limit &&
            <Grid item 
                key={product._id} 
                xs={12}
                sm={6}
                lg={4}
                display='flex' 
                flexDirection='column'
                alignItems='center' sx={{
                    marginBottom: '30px',
                }}>
                    {
                        matches && <SingleProductMobile product={product} matches={matches} /> 
                    }
                    {
                       !matches  && <SingleProductDesktop product={product} matches={matches} /> 
                    }
                    
            </Grid>
        ))] : []
    }, [filteredProducts, matches]) 
  return (
    <Container>
        <Grid container 
            spacing={{xs: 2, md: 4}}
            // columns={{xs: 4, sm: 8, md: 12}}
            justifyContent='center'
            sx={{
                margin: '20px 4px 10px 4px',
            }}>
                {renderProducts}
        </Grid>
    </Container>
  )
}

export default Products;
