import { Box, Button, Container, Grid, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import { PARTNERS } from '../../data/partners'
import { ImageCovered } from '../cartItem'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const Partners = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const matches = useMediaQuery(theme.breakpoints.down('md'))

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const renderPartners = useMemo(() => {
        return (
            [...PARTNERS?.map((partner, idx) => (
                <Grid item 
                key={partner.id} 
                xs={4}
                // sm={4}
                display='flex' 
                flexDirection='column'
                alignItems='center' sx={{
                    marginBottom: '30px',
                }}>
                    <Button onClick={() => openInNewTab(partner.url)}>
                    <ImageCovered src={partner.logo} alt={partner.title} sx={{maxWidth: '230px', maxHeight: '150px'}} />
                    </Button>
                   
            </Grid>
            ))]
        )
    }, [PARTNERS]) 
  return (
    <Container>
        <Grid container 
            spacing={{xs: 2, md: 4}}
            columns={{xs: 4, sm: 8, md: 12}}
            justifyContent='center'
            sx={{
                margin: '20px 4px 10px 4px',
            }}>
                {renderPartners}
        </Grid>
    </Container>
  )
}

export default Partners