import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Colors } from '../../styles/theme'
import { Product, ProductImage } from '../../styles/products'
import IncDec from '../../ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart } from '../../redux/global'
import useAlert from '../../hooks/useAlert'
import { SEVERITY_INFO } from '../../constants'


const slideTransition = (props) => {
    return <Slide direction='down' {...props} />
}

const ProductDetailWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
}))

const ProductDetailInfoWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    // minWidth: 400,
    // maxWidth: 500,
    maxWidth: 500,
    lineHeight: 1.5,
}))

export default function ProductDetail({ open, onClose, product}) {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [selectedImage, setSelectedImage] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [selectedSaleInfo, setSelectedSaleInfo] = useState('')
    const [updatedProduct, setUpdatedProduct] = useState(null)
    const dispatch = useDispatch()
    const intl = useIntl()
    const location = useSelector((state) => state.persisted.global.location) || process.env.REACT_APP_DEFAULT_MARKET

    const locale = useSelector((state) => state.persisted.global.locale)
    
    // const [productName, setProductName] = useState('')
    // const [productDescription, setProductDescription] = useState('')
    useEffect(() => {
        if (product) {
            setSelectedSaleInfo(product?.saleInfo[0]._id)
            setUpdatedProduct({...product})
        }
    }, [product])

    const handleAddToCart = () => {
        const saleInfoIdx = updatedProduct?.saleInfo?.findIndex((info) => {
            return info._id === selectedSaleInfo
        })
        dispatch(addProductToCart({
            product: updatedProduct,
            saleInfoIdx: saleInfoIdx,
            quantity: quantity,
        }))
    }

    const handleSelectSaleInfo = (value) => {
        setSelectedSaleInfo(value)
        const selectedSaleInfoItem = product?.saleInfo?.filter((item) => item._id === value)[0]
        if (selectedSaleInfoItem) {
            setUpdatedProduct((prev) => ({
                ...prev,
                price: selectedSaleInfoItem.price,
                special: selectedSaleInfoItem.special,
                sku: selectedSaleInfoItem.sku,
                unit: selectedSaleInfoItem.unit,
                level: selectedSaleInfoItem.level,
            }))
        }
    }
  return (
    <>
    <Dialog
        variant='permanent'
        open={open} 
        fullScreen>
        <DialogTitle sx={{
            color: Colors.white,
            background: Colors.secondary,
        }}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                {product?.name ? product?.name[locale] : ''}
                <IconButton onClick={onClose} sx={{color: Colors.white}}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            <ProductDetailWrapper flexDirection={ matches ? 'column' : 'row'}>
                <Product sx={!matches && { mr: 4 }}>
                    <ProductImage src={process.env.REACT_APP_S3_BUCKET_URL + product?.images[selectedImage]} />
                    <Box width='100%' mt={3} display='flex' flexDirection='row' justifyContent='center' alignItems='center' >
                    {product?.images?.map((item, idx) => {
                        if (item && item.length > 0) {
                            return (<Button key={item} 
                            sx={{width: '90px', height: '90px', 
                            mr: (idx < product?.images?.length - 1) ? 1 : 0, borderRadius: '10px', border: `2px solid ${Colors.green_300}`, p: 0.5, 
                            '&:hover': {
                                cursor:'pointer',
                            }}} onClick={() => setSelectedImage(idx)}>
                                <img src={process.env.REACT_APP_S3_BUCKET_URL + item} width='100%' height='100%' />
                            </Button>)
                        }
                    })}
                    </Box>
                </Product>
                <ProductDetailInfoWrapper mt={3}>
                <Typography variant='h4' sx={{
                        lineHeight: 2
                    }}>{product?.name ? product?.name[locale] : ''}</Typography>
                    <Typography variant='body'>
                    {product?.description ? product?.description[locale] : ''}
                    </Typography>
                    {product?.saleInfo?.length === 1 && product?.level && (
                        <Box sx={{
                            mt: 2
                        }} 
                            display='flex' 
                            alignItems='center'
                            justifyContent='space-between'>
                            <Box flex='1'>
                            <Typography variant='h6'><FormattedMessage id='shop.grade' /></Typography>
                            </Box>
                            <Box flex='1' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                            <Typography variant='h6'>{product?.level ? product?.level[locale] : ''}</Typography>
                            </Box>
                        </Box>
                    )}
                    {product?.saleInfo?.length > 1 && (
                        <Box sx={{
                            mt: 2
                        }} display='flex' 
                        alignItems='center'
                        justifyContent='space-between'>
                            <FormControl variant="standard" sx={{ m: 0, minWidth: 200 }}>
                                <InputLabel id="simple-select-label-level">
                                    <FormattedMessage id="shop.choose.level" />
                                </InputLabel>
                                <Select
                                variant="standard"
                                labelId="simple-select-label-level"
                                id="simple-select-level"
                                name="level"
                                value={selectedSaleInfo}
                                onChange={(e) => {
                                    handleSelectSaleInfo(e.target.value)
                                }}
                                >
                            
                                {product?.saleInfo?.map((item, idx) => (
                                    <MenuItem key={item?._id} value={item?._id}>
                                    {item.level ? item.level[locale] : ''}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    
                    <Box sx={{
                        mt: 2
                    }} 
                        display='flex' 
                        alignItems='center'
                        justifyContent='space-between'>
                        <Box flex='1'>
                        <Typography variant='h6'><FormattedMessage id='shop.unit' /></Typography>
                        </Box>
                        <Box flex='1' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                        <Typography variant='h6'>{updatedProduct?.unit ? updatedProduct?.unit[locale] : ''}</Typography>
                        </Box>
                    </Box>
                    
                    {updatedProduct?.sku > 0 && <Box sx={{
                            mt: 2
                        }} 
                        display='flex' 
                        alignItems='center'
                        justifyContent='space-between'>
                        <Box flex='1'>
                            <Typography variant='h6'><FormattedMessage id='shop.price' /></Typography>
                        </Box>
                        <Box flex='1' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                        {updatedProduct?.special < updatedProduct?.price ? (
                            <>
                            <Typography variant='h6' sx={{textDecoration: 'line-through', color: Colors.danger, mr: 2, fontSize: '1em'}}>
                            ${updatedProduct?.price.toFixed(2)}
                            </Typography>
                            <Typography variant='h5'>
                               ${updatedProduct?.special.toFixed(2)}
                            </Typography>
                            </>
                        ) : (<Typography variant='h6'>
                        ${updatedProduct?.price.toFixed(2)}
                        </Typography>)}
                        </Box>
                    </Box>}
                    {updatedProduct?.sku === 0 && (
                        <Box sx={{
                            mt: 2
                        }} 
                            display='flex' 
                            alignItems='center'
                            justifyContent='space-between'>
                            <Box flex='1'>
                            <Typography variant='h6' color={Colors.danger}><FormattedMessage id='commercial.sold.out' /></Typography>
                            </Box>
                        </Box>
                    )}
                    <Stack sx={{
                            mt: 3
                        }} 
                        display='flex' 
                        alignItems={mobile ? 'center' : 'flex-start'}
                        justifyContent='flex-start'>
                        <Box flex='1' mb={3}>
                        <IncDec value={quantity} setValue={setQuantity} />
                        </Box>
                        <Box flex='1' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                            <Button variant='contained' disabled={updatedProduct?.sku === 0} onClick={() => handleAddToCart()}>
                                <FormattedMessage id='shop.add.to.cart' />
                            </Button>
                        </Box>
                        
                    </Stack>
                    {/* <Box display='flex' 
                        justifyContent={matches ? 'center' : 'flex-start'}
                        alignItems='center' 
                        sx={{
                            mt: 3,
                            color: Colors.light,
                        }}>
                        <FavoriteIcon sx={{
                            mr: 2,
                        }} />
                        <Button variant='outlined'>
                        <FormattedMessage id='shop.add.wishlist' />
                        </Button>
                        
                    </Box> */}
                    {/* <Box sx={{
                            mt: 4,
                            color: Colors.light,
                        }}
                        display='flex'
                        alignItems='center'
                        justifyContent={matches ? 'center' : 'flex-start'}>
                        <FacebookIcon />
                        <TwitterIcon sx={{ pl: theme.spacing(4) }} />
                        <InstagramIcon sx={{ pl: theme.spacing(4) }} />
                    </Box> */}
                </ProductDetailInfoWrapper>
            </ProductDetailWrapper>
        </DialogContent>
    </Dialog>
    </>
  )
}
