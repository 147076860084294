import React, { useEffect, useState } from 'react'
import { Product, ProductActionButton, ProductActionsWrapper, ProductAddToCart, ProductFavButton, ProductImage } from '../../styles/products'
import { Stack } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import ProductMeta from './ProductMeta'
import useDialogModal from '../../hooks/useDialogModal'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart } from '../../redux/global'
import ProductDetail from '../modals/ProductDetail'
import useAlert from '../../hooks/useAlert'
import { SEVERITY_INFO } from '../../constants'
// import ProductDetail from '../product'

const SingleProductMobile = ({ product, matches }) => {
    const [ProductDetailDialog, showProductDetailDialog, closeProductDetailDialog] = useDialogModal(ProductDetail)
    const dispatch = useDispatch()
    const intl = useIntl()
    const location = useSelector((state) => state.persisted.global.location) || process.env.REACT_APP_DEFAULT_MARKET

    const handleAddToCart = () => {
        const saleInfoIdx = product.saleInfo.findIndex((info) => {
            return info.city.toLowerCase() === location.toLowerCase()
        })
        dispatch(addProductToCart({
            product: product,
            saleInfoIdx: saleInfoIdx,
            quantity: 1,
        }))
    }
    return (
    <>
        <Product>
            <ProductImage src={product.image} onClick={() => showProductDetailDialog()} />
            <ProductMeta product={product} matches={matches} />
            <ProductActionsWrapper>
                <Stack direction='row'>
                    {/* <ProductFavButton isFav={0}>
                        <FavoriteIcon />
                    </ProductFavButton> */}
                    {/* <ProductActionButton>
                        <ShareIcon color='primary' />
                    </ProductActionButton> */}
                    {/* <ProductActionButton onClick={() => showProductDetailDialog()}> */}
                    {/* <ProductActionButton onClick={() => showProductDetailDialog()}>
                        <FitScreenIcon color='primary' />
                    </ProductActionButton> */}
                </Stack>
            </ProductActionsWrapper>
        </Product>
        <ProductAddToCart disabled={product.sku === 0}  variant='contained' onClick={() => handleAddToCart()}>
            <FormattedMessage id='shop.add.to.cart' />
        </ProductAddToCart>
        <ProductDetailDialog product={product} />
    </>
  )
}

export default SingleProductMobile;