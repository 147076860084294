import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DefaultDialogButton, DefaultDialogDetails, DefaultDialogHead, DefaultDialogTitle, LanguageSelectButton } from '../../styles/dialog'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../styles/theme'

const ContactUs = ({ open, onClose}) => {
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth={'xs'}>
        <DefaultDialogTitle>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                <FormattedMessage id='app.contact' />
                <IconButton sx={{color: Colors.white}} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DefaultDialogTitle>
        <DialogContent>
            <Stack sx={{
                width: '100%',
                height: '100%',
                marginTop: '30px',
            }}>
                <DefaultDialogHead variant='h5'>
                    <FormattedMessage id='contact.us.customer.care' />
                </DefaultDialogHead>
                <DefaultDialogDetails variant='p'>
                    <FormattedMessage id='contact.us.details' values={{phone: process.env.REACT_APP_DEFAULT_CONTACT_PHONE, email: process.env.REACT_APP_DEFAULT_CONTACT_EMAIL}} />
                </DefaultDialogDetails>
                <LanguageSelectButton onClick={onClose}>
                    <FormattedMessage id='modal.ok' />
                </LanguageSelectButton>
            </Stack>
            
        </DialogContent>
    </Dialog>
  )
}

export default ContactUs