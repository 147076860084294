import React from 'react'
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { NavbarContainer, NavbarHeader, NavbarMainTitleMobile } from '../../../styles/bars'
import { useUIContext } from '../../../context/ui'
import { Colors } from '../../../styles/theme'
import { useSelector } from 'react-redux'
import NavbarDrawer from '../drawer/Drawer';
import { useNavigate } from 'react-router-dom';

const NavbarMobile = ({user, tablet, mobile, showLanguageSelectDialog, showCitySelectDialog, showPickupDialog, showLoginDialog }) => {
  const { setDrawerOpen, setShowSearchBox } = useUIContext()
  const navigate = useNavigate()
  // const user = useSelector((state) => state.persisted.global.currentUser)
  
  const cart = useSelector((state) => state.persisted.global.cart)

  return (
    <NavbarContainer type='mobile'>
        <IconButton sx={{color: Colors.white}} onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <NavbarMainTitleMobile type='mobile' textAlign={'center'} variant='h4' onClick={() => navigate('/')} sx={{
            '&:hover': {
                cursor: 'pointer',
            }
        }}>
          The Honest Fresh Produce
        </NavbarMainTitleMobile>
        <IconButton sx={{color: Colors.white}} onClick={() => navigate('/cart')}>
          <Badge badgeContent={cart?.quantity} color='warning'>
              <ShoppingCartIcon />
          </Badge>
        </IconButton>
        <NavbarDrawer user={user} showLanguageSelectDialog={showLanguageSelectDialog} showPickupDialog={showPickupDialog} showLoginDialog={showLoginDialog} />
    </NavbarContainer>
  )
}

export default NavbarMobile