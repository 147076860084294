import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DefaultDialogButton, DefaultDialogDetails, DefaultDialogHead, DefaultDialogTitle, LanguageSelectButton } from '../../styles/dialog'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../styles/theme'

const Pickup = ({ open, onClose, email, phone, address}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth={'xs'}>
        <DefaultDialogTitle>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                <FormattedMessage id='app.pickup' />
                <IconButton sx={{color: Colors.white}} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DefaultDialogTitle>
        <DialogContent>
            <Stack sx={{
                width: '100%',
                height: '100%',
                marginTop: '30px',
            }}>
                <DefaultDialogDetails variant='p'>
                    <FormattedMessage id='modal.pickup.details'/>
                </DefaultDialogDetails>
                <Stack width='100%' display='flex' justifyContent='flex-start' alignItems='center' mb={1}>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.green_800, mr: 1}}>
                        <FormattedMessage id='auth.email.address' />: 
                    </Typography>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.gray_800}}>
                        {email}
                    </Typography>
                </Stack>
                <Stack width='100%' display='flex' justifyContent='flex-start' alignItems='center' mb={1}>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.green_800, mr: 1}}>
                        <FormattedMessage id='auth.phone' />: 
                    </Typography>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.gray_800}}>
                        {phone}
                    </Typography>
                </Stack>
                <Stack width='100%' display='flex' justifyContent='flex-start' alignItems='center' mb={1}>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.green_800, mr: 1}}>
                        <FormattedMessage id='modal.pickup.location' />: 
                    </Typography>
                    <Typography variant='body' sx={{fontSize: '1.1em', color: Colors.gray_800, textAlign: 'center'}}>
                        {address}
                    </Typography>
                </Stack>
                <LanguageSelectButton onClick={onClose}>
                    <FormattedMessage id='modal.ok' />
                </LanguageSelectButton>
            </Stack>
            
        </DialogContent>
    </Dialog>
  )
}

export default Pickup