import { Box, Button, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const CarouselWrapper = styled(Paper)(({type}) => ({
    width: '100%',
    display: 'flex',
    flexDirection: (type === 'mobile') ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    position: 'relative',
    backgroundColor: Colors.body_bg,
    // border: '2px solid red',
}))
// Carousel Image
export const CarouselImageWrapper = styled(Box)(({type}) => ({
    flex: (type === 'mobile') ? 1 : 2,
}))
export const CarouselImage = styled('img')(({tablet, mobile}) => ({
    // width: tablet ? 'auto' :'100%',
    // height: tablet ? '100%' : 'auto',
    width: '100%',
    height: 'auto',
    // height: '100%',
    // width: 'auto',
    objectFit: 'cover',
}))

export const CarouselTextWrapper = styled(Box)(({type}) => ({
    flex: (type === 'mobile') ? 2 : 1,
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
}))

export const CarouselHeader = styled(Typography)(() => ({
    color: Colors.dark,
    marginBottom: '15px',
}))

export const CarouselDescription = styled(Typography)(() => ({
    fontSize: '18px',
    color: Colors.dark,
    
}))

export const CarouselButton = styled(Button)(() => ({
    color: Colors.dark,
    border: `1px solid ${Colors.dark}`,
    borderRadius: '5px',
    width: '150px',
    padding: "5px 5px",
    marginTop: '50px',
    fontSize: '16px',
    cursor: 'pointer',
    // '&:hover': {
    //     backgroundColor: 'rgba(237,231,246,0.5)',
    // }
}))

