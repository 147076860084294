import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { MenubarContainer, MenubarList, MenubarListItemButton } from '../../../styles/bars'

import { MENU } from '../../../data/menu'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { useUIContext } from '../../../context/ui';
import { SEVERITY_ERROR } from '../../../constants';
const MenubarDesktop = () => {
    const navigate = useNavigate()
    const { drawerOpen, setDrawerOpen, setShowSearchBox, setShowAlert, setAlertObj } = useUIContext()
    const handleMenuItem = (item) => {
        if (item.url === 'search') {
            setShowSearchBox(true)
            // setShowAlert(true)
            // setAlertObj({
            //     severity: SEVERITY_ERROR,
            //     content: 'abcabcabcabcabcabcabcabcabcabcabcabcabcabcabcabc',
            //     duration: null,
            // })
        } else if (item.state) {
            navigate(item.url, {state: item.state})
        } else {
            navigate(item.url)
        }
    }
  return (
    <MenubarContainer>
        <MenubarList>
            {MENU.map((item) => (
                <MenubarListItemButton key={item.id} onClick={() => handleMenuItem(item)}>
                    <FormattedMessage id={item.title} />
                    {item.title.indexOf('search') > 0 && (
                        <SearchIcon sx={{
                            marginLeft: '5px',
                            fontSize: '16px',
                        }} />
                    )}
                </MenubarListItemButton>
            ))}
        </MenubarList>
    </MenubarContainer>
  )
}

export default MenubarDesktop