import { Box, Button, Container, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react'
import { Formik } from "formik";
import * as yup from "yup";
import Navbar from '../../components/bars/navbar/Navbar';
import Menubar from '../../components/bars/menubar/Menubar';
import { Colors } from '../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateUserPasswordMutation } from '../../apis/auth';
import { REGEX_PASSWORD, REGEX_PHONE, SEVERITY_SUCCESS, SEVERITY_WARNING } from '../../constants';
import { useNavigate } from 'react-router-dom';
import useBackdrop from '../../hooks/useBackdrop';
import { setUser } from "../../redux/global";
import { useUIContext } from '../../context/ui';

const Password = () => {
  const theme = useTheme();
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tablet = useMediaQuery(theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const locale = useSelector((state) => state.persisted.global.locale)
  const user = useSelector(state => state.persisted.global.currentUser)?.data
  // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
  const [
      updateUserPassword, 
      { isLoading: isUpdating }, 
    ] = useUpdateUserPasswordMutation() 

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  const checkoutSchema = yup.object().shape({
    oldPassword: yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    newPassword: yup.string().matches(REGEX_PASSWORD, "error.invalid.password"),
    newPasswordConfirmation: yup.string()
      .oneOf([yup.ref('newPassword'), null], 'error.password.match'),
  });

  const handleFormSubmit = async (values) => {
      let submitObj = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
      // console.log('submitObj', submitObj)
      try {
        if (window.confirm(intl.formatMessage({id: 'window.ready.submit'})) === true) {
          setShowBackdrop(true)
          const params = {
              id: user?._id,
              token: user?.accessToken,
              body: submitObj,
            }
          const userInfo = await updateUserPassword({
            id: user?._id,
            token: user?.accessToken,
            body: submitObj,
          })
          setShowBackdrop(false)
          // console.log('userInfo.error', userInfo.error)
          if (userInfo.error) {
            setAlertObj({
                severity: SEVERITY_WARNING,
                content: userInfo.error.data[locale],
                duration: null,
            })
            setShowAlert(true)
          } else {
            setAlertObj({
                severity: SEVERITY_SUCCESS,
                content: intl.formatMessage({id: 'window.operation.successful'}),
                duration: null,
            })
            setShowAlert(true)
            dispatch(setUser(null))
            navigate('/')
          }
        }
      } catch (err) {
          setShowBackdrop(false)
          // window.alert(intl.formatMessage({id: 'window.operation.failed'}))
          setAlertObj({
            severity: SEVERITY_WARNING,
            content: intl.formatMessage({id: 'window.operation.failed'}),
            duration: null,
        })
        setShowAlert(true)
      }
    }

  return (
    <Stack width='100%' display='flex' justifyContent='center' alignItems='center' boxSizing={'border-box'}>
      <Stack width='100%' display='flex' justifyContent='center' alignItems='center' p={2} pt={5} boxSizing={'border-box'}>
                <Box mb={3}>
                    <Typography variant='h4'
                        color={theme.palette.secondary[100]}
                        fontWeight="bold">
                            <FormattedMessage id='auth.change.password' />
                    </Typography>
                </Box>
                <Box width={mobile ? '100%' : (tablet ? '60%' : '40%')}>
                    <Formik
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                        style={{border: '1px solid red'}}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack alignItems='flex-start' width='100%'>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    label={intl.formatMessage({id: 'auth.old.password'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    name="oldPassword"
                                    error={!!touched.oldPassword && !!errors.oldPassword}
                                    helperText={touched.oldPassword && errors.oldPassword && intl.formatMessage({id: errors.oldPassword}) }
                                    sx={{mb: 3}}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    label={intl.formatMessage({id: 'auth.new.password'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.newPassword}
                                    name="newPassword"
                                    error={!!touched.newPassword && !!errors.newPassword}
                                    helperText={touched.newPassword && errors.newPassword && intl.formatMessage({id: errors.newPassword})}
                                    sx={{mb: 3}}
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    label={intl.formatMessage({id: 'auth.password.confirmation'})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.newPasswordConfirmation}
                                    name="newPasswordConfirmation"
                                    error={!!touched.newPasswordConfirmation && !!errors.newPasswordConfirmation}
                                    helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation && intl.formatMessage({id: errors.newPasswordConfirmation})}
                                    sx={{mb: 3}}
                                />
                                <Button
                                    disabled={
                                        !!values.oldPassword === false || 
                                        !!values.newPassword === false || 
                                        !!values.newPasswordConfirmation === false || 
                                        !!errors.oldPassword || 
                                        !!errors.newPassword || 
                                        !!errors.newPasswordConfirmation || 
                                        isUpdating }
                                    sx={{
                                        width: '100%',
                                        backgroundColor: Colors.green_800,
                                        color: Colors.white,
                                        // color: theme.palette.background.alt,
                                        pt: 1.5,
                                        pb: 1.5,
                                        '&:hover': {
                                            backgroundColor: Colors.green_600,
                                        },
                                        "&:disabled": {
                                            backgroundColor: theme.palette.grey[300],
                                            color: theme.palette.grey[800],
                                            cursor: 'not-allowed',
                                            pointerEvents: 'none',
                                        }
                                }} type="submit"><FormattedMessage id='general.update' /></Button>
                            </Stack>
                        </form>
                        )}
                    </Formik>
                </Box>
            </Stack>
    </Stack>
  )
}

export default Password