import React, { useMemo } from 'react'
import { Paper, Button, Stack, Box } from '@mui/material'
import { CarouselButton, CarouselDescription, CarouselHeader, CarouselImage, CarouselImageWrapper, CarouselTextContainer, CarouselTextWrapper, CarouselWrapper } from '../../styles/carousel'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const Item = (props) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const type = useMemo(() => {
        return props.mobile ? 'mobile' : (props.tablet ? 'tablet' : 'desktop')
    }, [props.tablet, props.mobile])
    return (
        <CarouselWrapper type={type} elevation={0}>
            {props.item.layout === 'left-right' ? (
                <>
                <CarouselImageWrapper type={type} >
                    <CarouselImage type={type} src={props.item.image} alt='slider' />
                </CarouselImageWrapper>
                
                <CarouselTextWrapper>
                    <CarouselHeader variant='h4'>
                        <FormattedMessage id={props.item.title} />
                    </CarouselHeader>
                    <CarouselDescription variant='p'>
                        <FormattedMessage id={props.item.description} />
                    </CarouselDescription>
                    <CarouselButton onClick={() => navigate(props.item.url, {state: props.item.state})}>
                        <FormattedMessage id='slider.shop.now' />
                    </CarouselButton>
                </CarouselTextWrapper>
                </>
                
            ) : (
                <>
                <CarouselTextWrapper>
                    <CarouselHeader variant='h4'>
                        <FormattedMessage id={props.item.title} />
                    </CarouselHeader>
                    <CarouselDescription variant='p'>
                        <FormattedMessage id={props.item.description} />
                    </CarouselDescription>
                    <CarouselButton onClick={() => navigate(props.item.url, {state: props.item.state})}>
                        <FormattedMessage id='slider.shop.now' />
                    </CarouselButton>
                </CarouselTextWrapper>
                <CarouselImageWrapper>
                    <CarouselImage src={props.item.image} alt='slider' />
                </CarouselImageWrapper>
                </>
            )}
            
        </CarouselWrapper>
    )
}

export default Item