import { Backdrop, CircularProgress } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useUIContext } from '../context/ui'

export default function useBackdrop() {
    // const [open, setOpen] = useState(false)
    const { showBackdrop, setShowBackdrop } = useUIContext()

    // const openBackdrop = useCallback(() => {
    //     setOpen(true)
    // }, [])

    // const closeBackdrop = useCallback(() => {
    //     setOpen(false)
    // }, [])

    const BackdropComponent = useCallback(() => {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => (theme.zIndex.drawer > theme.zIndex.modal) ? (theme.zIndex.drawer + 1) : (theme.zIndex.modal + 1) }}
                open={showBackdrop}
                onClick={() => setShowBackdrop(false)}
                >
                <CircularProgress color="inherit" sx={{fontSize: '1.5em'}} />
            </Backdrop>
        )
    }, [showBackdrop])

    return [BackdropComponent]
}
