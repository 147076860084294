import { styled } from "@mui/material/styles";
import { Box, Typography, Button, List, IconButton, ListItemText, ListItemButton } from "@mui/material";
import { Colors } from "../theme";

// container
export const NavbarContainer = styled(Box)(({type}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: Colors.primary,
    position: 'sticky',
    zIndex: type === 'mobile' ? 600 : 999,
}))



export const NavbarLeftWrapper = styled(Box)(({type}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '300px',
}))

export const NavbarLeftList = styled(List)(({type}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
}))

export const NavbarMainTitle = styled(Typography)(({type}) => ({
    fontWeight: 500,
    fontStyle: 'normal',
    paddingTop: '15px',
    color: Colors.white
}))

export const NavbarMainTitleMobile = styled(Typography)(({type}) => ({
    padding: '4px',
    flexGrow: 2,
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: Colors.white,
    paddingTop: '10px',
    cursor: 'pointer',
}))

export const NavbarRightWrapper = styled(Box)(({type}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '300px',
}))

export const NavbarRightList = styled(List)(({type}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}))


export const NavbarListItemButton = styled(ListItemButton)((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '14px',
    padding: '5px 15px',
    color: Colors.white,
    '&& .MuiTypography-root': {
        fontFamily: 'Urbanist',
        textTransform: props.case,
    },
}))

export const MenubarContainer = styled(Box)(({type}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    backgroundColor: Colors.green_50,
    position: 'sticky',
    borderBottom: `1px solid ${Colors.border}`,
}))

export const MenubarList = styled(List)(({type}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2px',
    paddingBottom: '2px',
}))

export const MenubarListItemButton = styled(ListItemButton)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '14px',
    color: Colors.dark,
    fontFamily: 'Urbanist',
    '&& .MuiTypography-root': {
        fontFamily: 'Urbanist',
        textTransform: 'uppercase',
    },
}))

export const DrawerTopBar = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.gray_100,
    color: Colors.green_800,
    padding: '0px 10px',
    height: '64px',
    borderBottom: `1px solid ${Colors.border}`
}))

export const DrawerTopBarLeftButton = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '14px',
    padding: '5px 15px',
    color: Colors.green_800,
    '&& .MuiTypography-root': {
        fontFamily: 'Urbanist',
        textTransform: 'uppercase',
    },
}))

export const DrawerCloseButton = styled(IconButton)(() => ({
    color: Colors.green_800,
    border: `1px solid ${Colors.green_800}`
}))

export const DrawerList = styled(List)(() => ({
    borderBottom: `1px solid ${Colors.std_50}`,
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: Colors.gray_200,
    },
}))

export const DrawerItemButton = styled(ListItemButton)(() => ({
    fontFamily: 'Urbanist',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'capitalize',
    cursor: 'pointer',
    fontWeight: 400,
    padding: '15px 20px',
}))