import React, { useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { clamp } from './clamp'
import { Colors } from '../styles/theme'
import { INCDEC_SIZE_LARGE } from '../constants'


export default function IncDec({value, setValue, size=INCDEC_SIZE_LARGE}) {
    
    const clampV = clamp(size === INCDEC_SIZE_LARGE ? 1 : 0, process.env.REACT_APP_MAX_CLAMP)
  return (
    <Box display='flex' >
        <IconButton sx={{
                borderRadius: 0,
                width: size === INCDEC_SIZE_LARGE ? '60px' : '40px',
                height: size === INCDEC_SIZE_LARGE ? '60px' : '40px',
                backgroundColor: `${Colors.green_800}`,
                '&:hover': {
                    backgroundColor: Colors.green_600
                }
            }} 
            onClick={() => setValue(clampV(value - 1))}>
            <RemoveIcon sx={{color: Colors.white, fontSize: '1.2em'}} />
        </IconButton>
        <Typography variant={size === INCDEC_SIZE_LARGE ? 'h5' : 'h6'} sx={{
            width: size === INCDEC_SIZE_LARGE ? '34px' : '30px',
            height: size === INCDEC_SIZE_LARGE ? '26px' : '30px',
            border: `1px solid ${Colors.secondary}`,
            textAlign: 'center',
            p: size === INCDEC_SIZE_LARGE ? 2 : 0.5
        }}>{value}</Typography>
        <IconButton sx={{
                borderRadius: 0,
                width: size === INCDEC_SIZE_LARGE ? '60px' : '40px',
                height: size === INCDEC_SIZE_LARGE ? '60px' : '40px',
                backgroundColor: `${Colors.green_800}`,
                '&:hover': {
                    backgroundColor: Colors.green_600
                }
            }} 
            onClick={() => setValue(clampV(value + 1))}>
            <AddIcon sx={{color: Colors.white, fontSize: '1.2em'}} />
        </IconButton>
    </Box>
  )
}
