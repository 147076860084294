import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { Colors } from '../../styles/theme';
import { FormattedMessage } from 'react-intl';
import Products from '../../components/products/Products';
import useBackdrop from '../../hooks/useBackdrop';
import { useUIContext } from '../../context/ui';

const ProductsPage = ({isLoading}) => {
    const {state} = useLocation()
    const { tag, category } = state;
    
    const products = useSelector((state) => state.product.products)
    // console.log('***products', products)
    // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
    const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
    useEffect(() => {
      if (isLoading) {
        setShowBackdrop(true)
      } else {
        setShowBackdrop(false)
      }
    }, [isLoading])
    const title = useMemo(() => {
        if (tag === 'new') {
            return 'products.new'
        } else if (tag === 'special') {
            return 'products.specials'
        } else if (category?.toLowerCase() === 'vegetables') {
            return 'app.menu.vegetables'
        } else if (category?.toLowerCase() === 'fruits') {
            return 'app.menu.fruits'
        } else if (category?.toLowerCase() === 'eggs') {
            return 'app.menu.poultry'
        } else if (tag) {
            return 'general.search.results'
        }
    }, [tag, category])
  return (
    <>
        <Box display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center' width='100%' sx={{
          backgroundColor: Colors.gray_50,
          padding: '50px',
        }}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h4" sx={{
              width: '360px',
              textAlign: 'center',
              borderBottom: `2px solid ${Colors.green_700}`,
              color: Colors.primary}}><FormattedMessage id={title} />{` ${title === 'general.search.results' ? '(' + tag + ')' : ''}`}</Typography>
          </Box>
          <Products tag={tag} special={tag === 'special'} category={category} products={products} limit={products?.length} />
    </Box>
    </>
    

  )
}

export default ProductsPage