import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useVerifyEmailMutation } from '../../apis/auth'
import useBackdrop from '../../hooks/useBackdrop'
import useAlert from '../../hooks/useAlert'
import { useIntl } from 'react-intl'
import { Box, Stack, Typography } from '@mui/material'
import { Colors } from '../../styles/theme'
import { useUIContext } from '../../context/ui'

const VerifyEmail = () => {
  const { emailToken } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const [text, setText ] = useState(intl.formatMessage({id: 'auth.verifying.email'}))
  // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
  const [
    verifyEmail, 
    { isLoading: isUpdating }, 
  ] = useVerifyEmailMutation() 

  useEffect(async () => {
    if (emailToken) {
      const submitObj = {
        emailToken: emailToken
      }

      try {
        setShowBackdrop(true)
        const result = await verifyEmail(submitObj)
        if (result.error) {
          setShowBackdrop(false)
          setText(intl.formatMessage({id: 'auth.verifying.email.failed'}))
        } else {
          setText(intl.formatMessage({id: 'auth.verifying.email.success'}))
          setTimeout(() => {
            setShowBackdrop(false)
            navigate('/')
          }, 3000)
        }
      } catch (err) {
        setShowBackdrop(false)
        setText(intl.formatMessage({id: 'auth.verifying.email.failed'}))
      } 
    }
  }, [emailToken])
  return (
    <>
      <Stack width='100%' minHeight='400px' display='flex' justifyContent='flex-start' alignItems='center'>
        <Box mt={10}>
            <Typography variant='h4'
                color={Colors.green_800}
                fontWeight="bold"
                >
                    {text}
            </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default VerifyEmail