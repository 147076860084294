import React, { useEffect, useState } from 'react'
import {
    Box,
    Container,
    Stack,
    ThemeProvider,
    Typography,
  } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import Topbar from '../../components/bars/topbar/Topbar';
import Navbar from '../../components/bars/navbar/Navbar';
import { Colors } from '../../styles/theme';
import Menubar from '../../components/bars/menubar/Menubar';
import Carousel from '../../components/carousel/Carousel';
import Products from '../../components/products/Products';
import { FormattedMessage } from 'react-intl';
import Footer from '../../components/footer/Footer';
import useBackdrop from "../../hooks/useBackdrop";
import { useDispatch, useSelector } from 'react-redux';
import Partners from '../../components/partners';
import { useUIContext } from '../../context/ui';


const Home = ({isLoading}) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const products = useSelector((state) => state.product.products)
    const { setShowBackdrop } = useUIContext()

    const [newProducts, setNewProducts] = useState([])
    const [specialProducts, setSpecialProducts] = useState([])
    const [organicProducts, setOrganicProducts] = useState([])
    useEffect(() => {
        if (products) {
          setNewProducts(products?.filter(product => ((product.tags.indexOf('new') >= 0))))
          setSpecialProducts(products?.filter(product => ((product.special < product.price))))
          setOrganicProducts(products?.filter(product => ((product.tags.indexOf('organic') >= 0))))
        } 
    }, [products])

    useEffect(() => {
      if (isLoading) {
        setShowBackdrop(true)
      } else {
        setShowBackdrop(false)
      }
    }, [isLoading])
    // console.log('tablet', tablet, mobile)
  return (
    <>
      <Carousel tablet={tablet} mobile={mobile} />
      <Box display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center' sx={{
          backgroundColor: Colors.gray_50,
          padding: '50px',
        }}>
          {newProducts?.length > 0 && (
            <>
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                p: 4,
              }}
            >
              <Typography variant="h5" sx={{
                width: '360px',
                textAlign: 'center',
                borderBottom: `2px solid ${Colors.green_700}`,
                color: Colors.primary}}><FormattedMessage id='products.new' /></Typography>
            </Box>
            <Products tag={'new'} products={products} limit={3} />
            </>
          )}
          {specialProducts?.length > 0 && (
          <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h5" sx={{
              width: '360px',
              textAlign: 'center',
              borderBottom: `2px solid ${Colors.green_700}`,
              color: Colors.primary}}><FormattedMessage id='products.specials' /></Typography>
          </Box>
          <Products products={products} limit={3} special={true} />
          </>
          )}
          {organicProducts?.length > 0 && (
          <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h5" sx={{
              width: '360px',
              textAlign: 'center',
              borderBottom: `2px solid ${Colors.green_700}`,
              color: Colors.primary}}><FormattedMessage id='products.organic' /></Typography>
          </Box>
          <Products products={products} limit={3} tag={'organic'} />
          </>)}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h5" sx={{
              width: '360px',
              textAlign: 'center',
              borderBottom: `2px solid ${Colors.green_700}`,
              color: Colors.primary}}><FormattedMessage id='app.partners' /></Typography>
              
          </Box>
          <Partners />
      </Box>
    </>
  )
}

export default Home