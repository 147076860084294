import {
  Box,
  Container,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import {IntlProvider} from 'react-intl';
import { useEffect } from "react";
import theme from "./styles/theme";
import { UIProvider } from "./context/ui";
import Home from "./pages/home/Home";
import { messages } from './i18n/messages';
import { useDispatch, useSelector } from "react-redux";
import { useGetProductsQuery } from './apis/product'
import { setProducts } from "./redux/product";
import Profile from "./pages/profile/Profile";
import Password from "./pages/password/Password";
import { setCart, setLocation } from './redux/global';
import Cart from "./pages/cart/Cart";
import Layout from "./pages/Layout";
import Checkout from "./pages/checkout/Checkout";
import Orders from "./pages/orders/Orders";
import VerifyEmail from "./pages/verifyEmail";
import ResetPassword from "./pages/resetPassword";
import ProductsPage from "./pages/products";
import { EMPTY_CART } from "./constants";

function App() {
  const dispatch = useDispatch()
  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } );
  const user = useSelector(state => state.persisted.global.currentUser)
  const products = useSelector((state) => state.product.products)
  const location = useSelector((state) => state.persisted.global.location)
  const cart = useSelector((state) => state.persisted.global.cart)
  useEffect(() => {
    if (!location) {
      dispatch(setLocation(process.env.REACT_APP_DEFAULT_MARKET))
    }

    // if (!cart) {
    //   dispatch(setCart(EMPTY_CART))
    // }
  }, [location, cart])
    
  // const [processedProducts, setProcessedProducts] = useState([])

  // console.log('user', user)
  const { data: dataProducts, isLoading: isLoadingProducts } = useGetProductsQuery({
    page: 0,
    pageSize: process.env.REACT_APP_DEFAULT_PRODUCTS_LOAD,
    sort: JSON.stringify({}),
    search: '',
    vendor: process.env.REACT_APP_DEFAULT_VENDOR,
    location: process.env.REACT_APP_DEFAULT_MARKET,
  });

  // console.log('dataProducts', dataProducts)

  useEffect(() => {
      const processedProducts = dataProducts?.products?.map((item) => {
          let productObj = {...item,
            name: JSON.parse(item?.name),
            description: JSON.parse(item?.description),
            saleInfo: [...item.saleInfo.map((item) => {
              return {...item,
                level: (item.level?.length > 0) ? JSON.parse(item.level) : '',
                unit: JSON.parse(item.unit),
              }
            })],
            category: {
              ...item.category,
              title: JSON.parse(item.category.title),
            }
          }
          productObj.image = item.images.find((img) => img && img.length > 0)
          if (!productObj.image) {
            productObj.image = process.env.REACT_APP_DEFAULT_IMAGE
          } else {
            productObj.image = process.env.REACT_APP_S3_BUCKET_URL + productObj.image
          }

          const saleInfo = productObj.saleInfo.find((info) => info.price > 0)
          productObj.price = saleInfo ? saleInfo.price : 0
          productObj.special = saleInfo ? saleInfo.special : 0
          productObj.sku = saleInfo ? saleInfo.sku : 0
          productObj.unit = saleInfo ? saleInfo.unit : ''
          productObj.level = saleInfo ? saleInfo.level : ''
          productObj.available = saleInfo && saleInfo.available ? saleInfo.available : saleInfo.sku
          return productObj
      })
      dispatch(setProducts(processedProducts))
  }, [dataProducts?.products])

  // console.log('dataProducts?.products', dataProducts?.products)
  useEffect(() => {
    document.title = "Hones Fresh Produce";
    // dispatch(setCart(EMPTY_CART))
  }, []);
  return (
      <ThemeProvider theme={theme}>
        <IntlProvider 
          messages={messages[locale]}
          locale={locale}
          defaultLocale={locale}>
            <UIProvider>
              <Router>
                <Routes>
                  <Route element={<Layout />}>
                    <Route exact path='/' element={<Home isLoading={isLoadingProducts} />} />
                    <Route path='/profile' element={user ? <Profile /> : <Home isLoading={isLoadingProducts} />} />
                    <Route path='/password' element={user ? <Password /> : <Home isLoading={isLoadingProducts} />} />
                    <Route path='/verifyEmail/:emailToken' element={<VerifyEmail />} />
                    <Route path='/resetPassword/:emailToken' element={<ResetPassword />} />
                    <Route path='/products' element={<ProductsPage isLoading={isLoadingProducts} />} />
                    <Route path='/orders' element={user ? <Orders /> : <Home isLoading={isLoadingProducts} />} />
                    <Route path='/cart' element={<Cart />} />
                    <Route path='/checkout' element={user ? <Checkout /> : <Cart />} />
                  </Route>
                  
                  {/* <Route path='/login'>
                    {user ? <Redirect to='/' /> : <Login /> }
                  </Route> */}
                </Routes>
              </Router>
            </UIProvider>
        </IntlProvider>
      </ThemeProvider>
  );
}

export default App;
