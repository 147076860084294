import { api } from './api'

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation({
        query: (body) => ({
          url: `auth/register`,
          method: 'POST',
          body,
        }),
    }),
    login: build.mutation({
      query: (body) => ({
        url: `auth/login`,
        method: 'POST',
        body,
      }),
    }),
    ping: build.mutation({
      query: (token) => ({
        url: `auth/ping`,
        method: 'POST',
        headers: { token: `Bearer ${token}` },
      }),
    }),
    verifyEmail: build.mutation({
      query: (body) => ({
        url: `auth/verifyEmail`,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: `auth/resetPassword`,
        method: 'POST',
        body,
      }),
    }),
    resetPasswordByEmailToken: build.mutation({
      query: ({body}) => ({
        url: `auth/resetPasswordByEmailToken`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: build.mutation({
      query: ({id, token, body}) => ({
        url: `api/userById/${id}`,
        method: 'PUT',
        headers: { token: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUserPassword: build.mutation({
      query: ({id, token, body}) => ({
        url: `api/userPasswordById/${id}`,
        method: 'PUT',
        headers: { token: `Bearer ${token}` },
        body,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
  overrideExisting: false,
})

export const { useRegisterMutation, useLoginMutation, useVerifyEmailMutation, useResetPasswordMutation, useResetPasswordByEmailTokenMutation, useUpdateUserMutation, useUpdateUserPasswordMutation, usePingMutation } = authApi