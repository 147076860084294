import React, { useEffect, useState } from 'react'
import NavbarMobile from './NavbarMobile';
import NavbarDesktop from './NavbarDesktop';
import useDialogModal from '../../../hooks/useDialogModal';
import LanguageSelect from '../../modals/LanguageSelect';
import ContactUs from '../../modals/ContactUs';
import Login from '../../modals/Login';
import { useDispatch, useSelector } from 'react-redux';
import CitySelect from '../../modals/CitySelect';
import Pickup from '../../modals/Pickup';
import SearchBox from '../../search/Search';
import { useNavigate } from 'react-router-dom';
import { usePingMutation } from '../../../apis/auth';
import { setUser } from '../../../redux/global';
import { useUIContext } from '../../../context/ui';

const Navbar = ({tablet, mobile}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data; 
    const [LanguageSelectDialog, showLanguageSelectDialog, closeLanguageSelectDialog] = useDialogModal(LanguageSelect)
    const [CitySelectDialog, showCitySelectDialog, closeCitySelectDialog] = useDialogModal(CitySelect)
    const [ContactUsDialog, showContactUsDialog, closeContactUsDialog] = useDialogModal(ContactUs)
    const [PickupDialog, showPickupDialog, closePickupDialog] = useDialogModal(Pickup)
    const [LoginDialog, showLoginDialog, closeLoginDialog] = useDialogModal(Login)
    const { searchText, setSearchText } = useUIContext()


    const [
        ping, 
        { isLoading: isUpdating }, 
    ] = usePingMutation() 

    useEffect(() => {
        if (searchText) {
            navigate('/products', {state: {
              tag: searchText
          }})
        }
    }, [searchText])

    useEffect(async () => {
        if (user) {
            const result = await ping(user?.accessToken)
            if (result.error) {
                dispatch(setUser(null))
            }
        }
    }, [user])
    return (
        <>
            {(!tablet && !mobile) ?
                (<NavbarDesktop 
                    user={user} 
                    showLanguageSelectDialog={showLanguageSelectDialog} showCitySelectDialog={showCitySelectDialog} showContactUsDialog={showContactUsDialog} showPickupDialog={showPickupDialog} 
                    showLoginDialog={showLoginDialog} />) :
                (<NavbarMobile user={user} tablet={tablet} mobile={mobile} showLanguageSelectDialog={showLanguageSelectDialog} showCitySelectDialog={showCitySelectDialog} showContactUsDialog={showContactUsDialog} showPickupDialog={showPickupDialog} showLoginDialog={showLoginDialog} />) 
            }
            <LanguageSelectDialog />
            <CitySelectDialog />
            <ContactUsDialog phone={process.env.REACT_APP_DEFAULT_CONTACT_PHONE} email={process.env.REACT_APP_DEFAULT_CONTACT_EMAIL} />
            <PickupDialog phone={process.env.REACT_APP_DEFAULT_CONTACT_PHONE} email={process.env.REACT_APP_DEFAULT_CONTACT_EMAIL} address={process.env.REACT_APP_DEFAULT_PICKUP_LOCATION} />
            <LoginDialog mobile={mobile} tablet={tablet} />
            
        </>
    )
}

export default Navbar