import { useTheme } from '@emotion/react';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDialogModal from '../../hooks/useDialogModal';
import StripeCheckout from "react-stripe-checkout";
import { TypographyAdobe } from '../../components/TypographyAdobe';
import { Colors } from '../../styles/theme';
import { usePayStripeMutation } from '../../apis/cart';
import { useUpdateOrderByIdMutation } from '../../apis/order';
import OrderConfirmation from '../../components/modals/OrderConfirmation';
import useBackdrop from '../../hooks/useBackdrop';
import { setCart, setUnpaidOrder } from '../../redux/global';
import { useUIContext } from '../../context/ui';
import { EMPTY_CART, SEVERITY_WARNING } from '../../constants';

const Checkout = () => {
    const theme = useTheme();
    const intl = useIntl()
    const [quantity, setQuantity] = useState(1)
    const [stripeToken, setStripeToken] = useState(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const cart = useSelector((state) => state.persisted.global.cart)
    const unpaidOrder = useSelector((state) => state.persisted.global.unpaidOrder)?.data
    const user = useSelector(state => state.persisted.global.currentUser)?.data; 
    const [OrderConfirmationDialog, showOrderConfirmationDialog, closeOrderConfirmationDialog] = useDialogModal(OrderConfirmation)
    // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
    const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
    const [
        payStripe, 
        { isLoading: isPaying }, 
    ] = usePayStripeMutation() 

    const [
        updateOrderById, 
        { isLoading: isUpdatingOrder }, 
    ] = useUpdateOrderByIdMutation() 

    const onToken = (token) => {
        setStripeToken(token);
    };

    useEffect(() => {
        const makeRequest = async () => {
          try {
            setShowBackdrop(true)
            const res = await payStripe({
                token: user?.accessToken,
                body: {
                    tokenId: stripeToken.id,
                    amount: parseInt(unpaidOrder?.total * 100),
                    client: process.env.REACT_APP_CURRENT_CLIENT,
                }
            });

            if (res.error) {
                window.alert(intl.formatMessage({id: 'shop.payment.failed'}))
            } else {
                const submitObj = {
                    status: 'paid',
                    currrentStatus: unpaidOrder?.status,
                }          

                setShowBackdrop(true)
                const orderInfo = await updateOrderById({
                    id: unpaidOrder?._id,
                    token: user?.accessToken,
                    body: submitObj,
                })

                setShowBackdrop(false)
                if (orderInfo.error) {
                    // window.alert(intl.formatMessage({id: 'shop.order.update.failed'}))
                    setAlertObj({
                        severity: SEVERITY_WARNING,
                        content: intl.formatMessage({id: 'shop.order.update.failed'}),
                        duration: null,
                    })
                    setShowAlert(true)
                } else {
                    dispatch(setCart(EMPTY_CART))
                    dispatch(setUnpaidOrder(null))
                    showOrderConfirmationDialog()
                }
            }
            
          } catch {
            setShowBackdrop(false)
            setAlertObj({
                severity: SEVERITY_WARNING,
                content: intl.formatMessage({id: 'shop.order.update.failed'}),
                duration: null,
            })
            setShowAlert(true)
          }
        };

        if (unpaidOrder?.total > 0 && stripeToken) {
            makeRequest()
        }
    }, [stripeToken, unpaidOrder?.total]);

  return (
    <>
    <Stack width='100%' display='flex' justifyContent='center' alignItems='center'>
        <Box width={mobile ? '80%' : (tablet ? '50%' : '30%')} display='flex' justifyContent='center' alignItems='center' pt={5} mb={3} borderBottom={`2px solid ${Colors.green_700}`}>
            <Typography variant='h4'
                color={Colors.green_800}
                fontWeight="bold"
                textTransform='uppercase'>
                    <FormattedMessage id='shop.order.summary' />
            </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'  width={mobile ? '80%' : (tablet ? '50%' : '30%')} mb={3}>
            <TypographyAdobe variant='h5' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.subtotal' />: </TypographyAdobe>
            <TypographyAdobe variant='h5'>${unpaidOrder?.subtotal.toFixed(2)}</TypographyAdobe>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'  width={mobile ? '80%' : (tablet ? '50%' : '30%')} mb={3}>
            <TypographyAdobe variant='h5' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.discount' />: </TypographyAdobe>
            <TypographyAdobe variant='h5'>${unpaidOrder?.discount.toFixed(2)}</TypographyAdobe>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'  width={mobile ? '80%' : (tablet ? '50%' : '30%')} mb={3}>
            <TypographyAdobe variant='h5' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.tax' />: </TypographyAdobe>
            <TypographyAdobe variant='h5'>${unpaidOrder?.tax.toFixed(2)}</TypographyAdobe>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'  width={mobile ? '80%' : (tablet ? '50%' : '30%')} borderBottom={`1px solid ${Colors.gray_300}`}>
            <TypographyAdobe variant='h5' sx={{mr: 1, color: Colors.green_900}}><FormattedMessage id='shop.total' />: </TypographyAdobe>
            <TypographyAdobe variant='h4'>${unpaidOrder?.total.toFixed(2)}</TypographyAdobe>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'  width={mobile ? '80%' : (tablet ? '50%' : '30%')}  mb={3}>
        <StripeCheckout
            name="Honest Fresh Produce"
            image="https://test-birtoo.s3-ca-central-1.amazonaws.com/vendors/Honest_Fresh_Produce_logo.jpeg"
            billingAddress
            // shippingAddress
            description={`Your order total is $${cart.total.toFixed(2)}`}
            amount={cart.total * 100}
            token={onToken}
            stripeKey={process.env.REACT_APP_STRIPE}
        >
        <Button fullWidth variant='contained' sx={{mt: 3, mb: 3}}>
            <FormattedMessage id='shop.pay.now' />
        </Button>
        </StripeCheckout>
        {/* <Button fullWidth variant='contained' sx={{m: 3}}>
            <FormattedMessage id='shop.pay.now' />
        </Button> */}
        </Box>
    </Stack>
    <OrderConfirmationDialog />
    </> 
  )
}

export default Checkout