// URLs
export const URL_HOME = '/'
export const URL_PRODUCTS = '/products'
export const URL_PRODUCT = '/product/:id'
export const URL_CART = '/cart'
export const URL_CHECKOUT = '/checkout'
export const URL_CONTACT = '/contact'
export const URL_PICKUP = '/pickup'
export const URL_LOGIN = '/login'
export const URL_REGISTER = '/register'

export const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/
export const REGEX_PHONE =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
export const REGEX_URL = /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/


// Alert severity
export const SEVERITY_SUCCESS = 'success'
export const SEVERITY_ERROR = 'error'
export const SEVERITY_INFO = 'info'
export const SEVERITY_WARNING = 'warning'

// Button size
export const INCDEC_SIZE_LARGE = 1
export const INCDEC_SIZE_SMALL = 2

export const TEXTAREA_MAX_LENGTH = 150

export const EMPTY_CART = {
  vendor: '',
  notes: '',
  shipping: 'pickup',
  subtotal: 0,
  discount: 0,
  tax: 0,
  total: 0,
  quantity: 0,
  products: []
}