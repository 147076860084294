import React, {useMemo} from 'react';
import CarouselWrapper from 'react-material-ui-carousel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Paper, Button, Box } from '@mui/material'
import { SLIDES } from '../../data/carousel'
import Item from './Item'
import { Colors } from '../../styles/theme';

const Carousel = ({tablet, mobile}) => {
    const type = useMemo(() => {
        return mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')
    }, [tablet, mobile])

    return (
        <Box sx={{
            width: '100%',
            height: (type === 'tablet' ? '500px' : (type === 'mobile' ? '900px' : '800px')),
            // border: '2px solid green',
        }}>
            <CarouselWrapper 
                navButtonsAlwaysVisible
                indicators={false}
                interval={10000}
                duration={600}
                swipe={true}
                animation='fade'
                navButtonsProps={{
                    style: {
                        backgroundColor: Colors.primary,
                        border: '2px solid white',
                        cursor: 'pointer',
                        opacity: '0.5',
                    }
                }}
                NextIcon={<ArrowForwardIosIcon />}
                PrevIcon={<ArrowBackIosIcon />}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {
                    SLIDES.map( (item) => <Item key={item.id} item={item} tablet={tablet} mobile={mobile} /> )
                }
            </CarouselWrapper>
        </Box>
    )
}

export default Carousel