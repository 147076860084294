import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl'
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "../../redux/global";
import { REGEX_PASSWORD, REGEX_PHONE, SEVERITY_ERROR, SEVERITY_SUCCESS } from '../../constants'
import { Colors } from "../../styles/theme";
import { useUIContext } from "../../context/ui";

const SignIn = ({onClose}) => {
  const theme = useTheme()
  const intl = useIntl()
  const locale = useSelector((state) => {
    return state.persisted.global.locale
  } );
  const dispatch = useDispatch()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
  const [
    login, 
    { isLoading: isUpdating }, 
  ] = useLoginMutation() 

  const initialValues = {
    email: "",
    password: "",
  };

  const checkoutSchema = yup.object().shape({
    email: yup.string().email('error.invalid.email').required('error.required'),
    password: yup.string().matches(REGEX_PASSWORD, "error.invalid.password")
    .required("error.required"),
  });

  const handleFormSubmit = async (values) => {
    try {
      const credential = {
          username: values.email,
          password: values.password,
          userType: 'user',
      }

      setShowBackdrop(true)
      const result = await login(credential)
      if (result.error) {
        const errorInfo = result.error?.data
        setShowBackdrop(false)
        onClose()
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_ERROR,
          content: errorInfo[locale],
          duration: null,
        })
      } else {
        setShowBackdrop(false)
        onClose()
        setShowAlert(true)
        setAlertObj({
          severity: SEVERITY_SUCCESS,
          content: intl.formatMessage({id: 'auth.login.success'}),
          duration: null,
        })
        dispatch(setUser(result))
      }
    } catch (err) {
      console.log('err', err)
      setShowBackdrop(false)
      onClose()
      setShowAlert(true)
      setAlertObj({
        severity: SEVERITY_ERROR,
        content: intl.formatMessage({id: 'auth.login.failed'}) + JSON.stringify(err),
        duration: null,
      })
    }
    
  };

  return (
    <Stack
        width="100%"
        mt={5}
        // p={3}
        alignItems="flex-start"
      >
        <Typography
          variant="h4"
          color={Colors.green_800}
          fontWeight="bold"
          sx={{ mb: 2, textTransform: 'uppercase' }}
        >
          <FormattedMessage id='app.login' />
        </Typography>
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label={`${intl.formatMessage({id: 'auth.email.address'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email &&  intl.formatMessage({id: errors.email})}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="password"
                            label={`${intl.formatMessage({id: 'auth.password'})}*`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password && intl.formatMessage({id: errors.password})}
                            sx={{mb: 3}}
                        />
                        <Button fullWidth
                            disabled={!!values.email === false || 
                                !!values.password === false || 
                                !!errors.password  || 
                                !!errors.email || 
                                isUpdating }
                            sx={{
                              backgroundColor: Colors.primary,
                              color: Colors.white,
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px",
                              '&:hover': {
                                  backgroundColor: Colors.green_600,
                              },
                              "&:disabled": {
                                  backgroundColor: theme.palette.grey[300],
                                  color: theme.palette.grey[800],
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none',
                              }
                        }} type="submit"><FormattedMessage id='app.login' /></Button>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
      </Stack>
  )
}

export default SignIn