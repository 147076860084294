import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const FooterTitle = styled(Typography)(() => ({
    textTransform: 'uppercase',
    paddingLeft: '15px',
    borderLeft: `5px solid ${Colors.green_600}`,
    marginBottom: '1em',
}))

export const FooterCertImage = styled('img')(() => ({
    width: '240px',
    height: 'auto',
    marginBottom: '1.2em',
}))

export const SubscribeTf = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        color: Colors.secondary,
    },
    '.MuiInput-root::before': {
        borderBottom: `1px solid ${Colors.secondary}`,
    },
}))