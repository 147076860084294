import { useTheme } from '@emotion/react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Colors } from '../../styles/theme'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageCovered } from '../cartItem'
import { FlexBetween, FlexStart } from '../flexBetween'
import { TypographyAdobe } from '../TypographyAdobe'

const OrderDetail = ({ open, onClose, order, tablet, mobile}) => {
    const theme = useTheme()
    const intl = useIntl()
    // const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useDispatch()
    const locale = useSelector((state) => state.persisted.global.locale)
    // console.log('order', order)
  return (
    // <Dialog TransitionComponent={slideTransition} 
    <Dialog
        variant='permanent'
        open={open} 
        fullScreen>
        <DialogTitle sx={{
            color: Colors.white,
            background: Colors.secondary,
        }}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                <FormattedMessage id='shop.order.id' />: {order?._id}
                <IconButton onClick={onClose} sx={{color: Colors.white}}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Stack width='100%' justifyContent='center' alignItems='center'>
                <Box width={mobile ? '100%' : (tablet ? '60%' : '40%')} mt={3}>
                    <TypographyAdobe variant='h5' sx={{mb: 3, width: '100%'}}><FormattedMessage id='shop.order.products' /></TypographyAdobe>
                    {order?.products?.map((item, idx) => (
                        <Box key={item?._id + '-' + idx} width='100%' mb={2} pb={2} display='flex' justifyContent='flex-start' alignItems='center' borderBottom={`1px solid ${Colors.green_600}`}>
                            <ImageCovered src={process.env.REACT_APP_S3_BUCKET_URL + item.image} width='120px' height='120px' sx={{mr: 3, 
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.22)',}} />
                            <Stack width='100%'>
                                
                                <FlexStart width='100%'>
                                <Typography variant='p' sx={{mr: 2}}><FormattedMessage id='product.name' />: </Typography>
                                <Typography variant='p'>{JSON.parse(item.name)[locale]}</Typography>
                                </FlexStart>
                                {item.level?.length > 0 && (
                                    <FlexStart width='100%'>
                                        <Typography variant='p' sx={{mr: 2}}><FormattedMessage id='shop.grade' />: </Typography>
                                        <Typography variant='p'>{JSON.parse(item.level)[locale]}</Typography>
                                    </FlexStart>
                                )}
                                <FlexStart width='100%'>
                                <Typography variant='p' sx={{mr: 2}}><FormattedMessage id='shop.price' />: </Typography>
                                <Typography variant='p'>{`${item.price.toFixed(2)} (${item.quantity})`}</Typography>
                                </FlexStart>
                                <FlexStart width='100%'>
                                <Typography variant='p' sx={{mr: 2}}><FormattedMessage id='shop.unit' />: </Typography>
                                <Typography variant='p'>{JSON.parse(item.unit)[locale]}</Typography>
                                </FlexStart>
                                <FlexStart width='100%'>
                                <Typography variant='p' sx={{mr: 2}}><FormattedMessage id='shop.subtotal' />: </Typography>
                                <Typography variant='p'>${item.subtotal}</Typography>
                                </FlexStart>
                                
                            </Stack>
                        </Box>
                    ))}
                </Box>
                <Stack width={mobile ? '100%' : (tablet ? '60%' : '40%')} mt={3}>
                    <TypographyAdobe variant='h5' mb={3}><FormattedMessage id='shop.order.summary' />:</TypographyAdobe>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='shop.order.id' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='p' color={Colors.warning} sx={{fontSize: '1.2em'}}>{order?._id}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='modal.pickup.location' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='p' sx={{fontSize: '1.2em'}}>{order?.address}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='shop.subtotal' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='p' sx={{fontSize: '1.2em'}}>${order?.subtotal.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='shop.discount' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='p' sx={{fontSize: '1.2em'}}>${order?.discount.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='shop.tax' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='p' sx={{fontSize: '1.2em'}}>${order?.tax.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-start' alignItems='flex-start' mb={2}>
                        <Box flex={1}>
                        <Typography variant='p' sx={{mr: 2, width: '100px', fontSize: '1.1em', color: Colors.teal_800}}><FormattedMessage id='shop.total' />:</Typography>
                        </Box>
                        <Box flex={3}>
                        <Typography variant='h5' sx={{color: Colors.green_800}}>${order?.total.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
            
        </DialogContent>
    </Dialog>
  )
}

export default OrderDetail