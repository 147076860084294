// import { Snackbar, MuiAlert } from '@mui/material'
import React, { useCallback, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useUIContext } from '../context/ui';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={2} ref={ref} variant="filled" {...props} />;
});

export default function useAlert() {

    const { alertObj, showAlert, setShowAlert } = useUIContext()
    const closeAlert = () => {
        setShowAlert(false)
    }

    // console.log('duration', alertObj.duration)
    const AlertComponent = useCallback(() => {
        return (
            <Snackbar open={showAlert} 
                sx={{ height: "100%" }}
                autoHideDuration={alertObj.duration ? Number(alertObj.duration) : null} 
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                onClose={() => closeAlert()}>
                <Alert onClose={() => closeAlert()} severity={alertObj.severity} sx={{ 
                    width: '100%', 
                }}>
                {alertObj.content}
                </Alert>
            </Snackbar>
        )
    }, [showAlert, alertObj?.duration, alertObj?.content, alertObj?.severity])

    return [AlertComponent]
}
