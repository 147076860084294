import { useTheme } from "@emotion/react";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../styles/theme";
import { useGetOrdersByUserIdQuery } from "../../apis/order";
import OrderDetail from "../../components/modals/OrderDetail";
import moment from "moment";
import useDialogModal from "../../hooks/useDialogModal";
import useBackdrop from "../../hooks/useBackdrop";
import { useUIContext } from "../../context/ui";

const Orders = () => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sort, setSort] = useState({});
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext();
  const user = useSelector((state) => state.persisted.global.currentUser)?.data;

  const [OrderDetailDialog, showOrderDetailDialog, closeOrderDetailDialog] =
    useDialogModal(OrderDetail);

  const { data: dataOrders, isLoading: isLoadingOrders } =
    useGetOrdersByUserIdQuery({
      id: user?._id,
      page: 0,
      pageSize: 20,
      sort: JSON.stringify({ createdAt: "desc" }),
      vendor: process.env.REACT_APP_DEFAULT_VENDOR,
      client: process.env.REACT_APP_CURRENT_CLIENT,
      token: user?.accessToken,
    });

  useEffect(() => {
    if (isLoadingOrders) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }
  }, [isLoadingOrders]);
  const columns = [
    {
      field: "_id",
      headerName: intl.formatMessage({ id: "shop.order.id" }),
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setSelectedRow(params.row);
              showOrderDetailDialog();
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "total",
      headerName: intl.formatMessage({ id: "shop.total" }),
      flex: 0.75,
      renderCell: (params) => {
        return "$" + params.value.toFixed(2);
      },
    },
    {
      field: "status",
      headerName: intl.formatMessage({ id: "general.status" }),
      flex: 0.75,
      renderCell: (params) => {
        return intl.formatMessage({ id: "shop.status." + params.value });
      },
    },
    {
      field: "createdAt",
      headerName: intl.formatMessage({ id: "general.date" }),
      flex: 1,
      renderCell: (params) => {
        return moment(params.value).format("YYYY-MM-DD HH:mm");
      },
    },
  ];
  return (
    <>
      <Stack
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={5}
      >
        <Box mb={3}>
          <Typography
            variant="h4"
            color={Colors.green_800}
            borderBottom={`1px solid ${Colors.green_800}`}
            pl={10}
            pr={10}
            fontWeight="bold"
          >
            <FormattedMessage id="auth.orders" />
          </Typography>
        </Box>
        <Box
          width={mobile ? "100%" : "80%"}
          // minWidth="600px"
          maxWidth={mobile ? "360px" : "1000px"}
          overflow="auto"
        >
          <Box minWidth="600px">
            <DataGrid
              loading={isLoadingOrders}
              getRowId={(row) => row._id}
              rows={(dataOrders && dataOrders.orders) || []}
              rowHeight={120}
              columns={columns}
              rowCount={(dataOrders && dataOrders.total) || 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              sortingMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              // components={{ Toolbar: DataGridCustomToolbar }}
              // componentsProps={{
              // toolbar: { searchInput, setSearchInput, setSearch },
              // }}
            />
          </Box>
        </Box>
      </Stack>
    <OrderDetailDialog order={selectedRow} tablet={tablet} mobile={mobile} />
    </>
  );
};

export default Orders;
