import React, { useEffect, useState } from 'react'
import { Product, ProductActionButton, ProductActionsWrapper, ProductAddToCart, ProductFavButton, ProductImage } from '../../styles/products'
import { Stack } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import ProductMeta from './ProductMeta'
import useDialogModal from '../../hooks/useDialogModal'
import ProductDetail from '../modals/ProductDetail'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart } from '../../redux/global'
import useAlert from '../../hooks/useAlert'
import { SEVERITY_INFO } from '../../constants'
// import ProductDetail from '../product'

const SingleProductDesktop = ({ product, matches }) => {
    const [showOptions, setShowOptions] = useState(false)
    // const cart = useSelector((state) => state.persisted.global.cart)
    const dispatch = useDispatch()
    const intl = useIntl()
    const [ProductDetailDialog, showProductDetailDialog, closeProductDetailDialog] = useDialogModal(ProductDetail)
    const location = useSelector((state) => state.persisted.global.location) || process.env.REACT_APP_DEFAULT_MARKET

    const handleMouseEnter = () => {
        setShowOptions(true)
    }

    const handleMouseLeave = () => {
        setShowOptions(false)
    }

    const handleAddToCart = () => {
        const saleInfoIdx = product.saleInfo.findIndex((info) => {
            return info.city.toLowerCase() === location.toLowerCase()
        })
        dispatch(addProductToCart({
            product: product,
            saleInfoIdx: saleInfoIdx,
            quantity: 1,
        }))
    }


  return (
    <>
        <Product 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}>
            <ProductImage src={product.image} sx={{
                '&:hover': {
                    cursor: 'pointer',
                }
            }} onClick={() => showProductDetailDialog()} />
            {/* <ProductFavButton isFav={0}>
                <FavoriteIcon />
            </ProductFavButton> */}
            { showOptions && 
                <ProductAddToCart disabled={product.sku === 0} show={showOptions} variant='contained' onClick={() => handleAddToCart()}>
                    <FormattedMessage id='shop.add.to.cart' />
                </ProductAddToCart>
            }
            {/* <ProductActionsWrapper show={showOptions}>
                <Stack direction='column'>
                    <ProductActionButton onClick={() => showProductDetailDialog()}>
                        <FitScreenIcon color='primary' />
                    </ProductActionButton>
                </Stack>
            </ProductActionsWrapper> */}
        </Product>
        <ProductMeta product={product} matches={matches} />
        <ProductDetailDialog product={product} />
    </>
  )
}

export default SingleProductDesktop;
