import React, { useState } from 'react'
import { Box, IconButton, Slide, Stack } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useUIContext } from '../../context/ui'
import { SearchBoxContainer, SearchField } from '../../styles/search'
import { useIntl } from 'react-intl'

export default function SearchBox({setSearchText}) {
    const intl = useIntl()
    const { showSearchBox, setShowSearchBox } = useUIContext()
    const [searchValue, setSearchValue] = useState('')

    return (
        <Slide direction='down' in={showSearchBox} timeout={500}>
            <SearchBoxContainer>
                <Box width='100%' mt={30} display='flex' justifyContent='center' alignItems='center'>
                    <SearchField 
                        color='secondary' 
                        variant='standard' 
                        fullWidth 
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={`${intl.formatMessage({id: 'app.menu.search'})} ...`} />
                    <IconButton onClick={() => {
                        setSearchText(searchValue)
                        setShowSearchBox(false)
                    }}>
                        <SearchIcon sx={{
                            fontSize: { xs: '2rem', md: '3rem'},
                        }} color='secondary' />
                    </IconButton>
                    <IconButton sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10
                    }} onClick={() => {
                        setShowSearchBox(false)
                    }}>
                        <CloseIcon sx={{
                            fontSize: '4rem',
                        }} color='secondary' />
                    </IconButton>
                </Box>
            </SearchBoxContainer>
        </Slide>
    )
}
