import React, { useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DefaultDialogButton, DefaultDialogDetails, DefaultDialogHead, DefaultDialogTitle, LanguageSelectButton } from '../../styles/dialog'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../styles/theme'
import SignIn from '../signin/SignIn'
import ForgotPassword from '../forgotPassword'
import { Close } from '@mui/icons-material'
import SignUp from '../signup/SignUp'

const MODE_LOGIN = 1
const MODE_REGISTER = 2
const MODE_FORGOT_PASSWORD = 3

const Login = ({ open, onClose, mobile, tablet}) => {
    const [mode, setMode] = useState(MODE_LOGIN)
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth={mobile ? 'xs' : 'md'}>
        <DialogContent sx={{position: 'relative'}}>
            <IconButton sx={{position: 'absolute', top: 0, right: 0}} onClick={() => onClose()}>
                <Close sx={{fontSize: '2em',}} />
            </IconButton>
            <Stack sx={{
                width: '100%',
                height: '100%',
            }}>
                <Box display='flex' flexDirection={mobile ? 'column' : 'row'} justifyContent='flex-between' alignItems='center' width='100%'>
                   <Stack width={mobile ? '100%' : '66%'} p={3}>
                        {mode === MODE_LOGIN && (
                            <>
                            <SignIn onClose={onClose} />
                            <Box width='100%' display='flex' justifyContent='center' alignItems='center' pt={3} pb={2}>
                                <Typography variant='p' onClick={() => {setMode(MODE_FORGOT_PASSWORD)}} sx={{
                                    '&:hover': {
                                        color: Colors.green_500,
                                        cursor: 'pointer',
                                    },
                                }}><FormattedMessage id='auth.i.forgot.password'></FormattedMessage></Typography>
                            </Box>
                            </>
                        )}
                        {mode === MODE_FORGOT_PASSWORD && (
                            <>
                            <ForgotPassword onClose={onClose} />
                            <Box width='100%' display='flex' justifyContent='center' alignItems='center' pt={3} pb={2}>
                                <Typography variant='p' onClick={() => {setMode(MODE_LOGIN)}} sx={{
                                    '&:hover': {
                                        color: Colors.green_500,
                                        cursor: 'pointer',
                                    },
                                }}><FormattedMessage id='auth.go.to.login'></FormattedMessage></Typography>
                            </Box>
                            </>
                        )}
                        {mode === MODE_REGISTER && (
                            <>
                            <SignUp onClose={onClose} />
                            </>
                        )}
                   </Stack>
                   <Stack width={mobile ? '100%' : '34%'} display='flex' justifyContent='center' alignItems='center'>
                        <Typography variant='h6' sx={{textTransform: 'uppercase', textAlign: 'center', mb:2}}>{(mode === MODE_LOGIN || mode === MODE_FORGOT_PASSWORD) ? (<FormattedMessage id='auth.create.account' />) : (<FormattedMessage id='auth.already.have.account' />)}
                        </Typography>
                        <Typography variant='p' sx={{textAlign: 'center', mb: 2}}><FormattedMessage id='auth.discover.farm' /></Typography>
                        {(mode === MODE_LOGIN || mode === MODE_FORGOT_PASSWORD) ? (<Button variant='outlined' sx={{border: `1px solid ${Colors.green_700}`, pl: 3, pr: 3, textTransform: 'uppercase'}} onClick={() => setMode(MODE_REGISTER)}><FormattedMessage id='auth.new.customer' /></Button>) : (<Button variant='outlined' sx={{border: `1px solid ${Colors.green_700}`, pl: 3, pr: 3, textTransform: 'uppercase'}} onClick={() => setMode(MODE_LOGIN)}><FormattedMessage id='app.login' /></Button>)}
                   </Stack>
                </Box>
            </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default Login