import { Box, Button, Container, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react'
import { Formik } from "formik";
import * as yup from "yup";
import Navbar from '../../components/bars/navbar/Navbar';
import Menubar from '../../components/bars/menubar/Menubar';
import { Colors } from '../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateUserMutation } from '../../apis/auth';
import { REGEX_PHONE, SEVERITY_SUCCESS, SEVERITY_WARNING } from '../../constants';
import { useNavigate } from 'react-router-dom';
import useBackdrop from '../../hooks/useBackdrop';
import { setUser } from "../../redux/global";
import { useUIContext } from '../../context/ui';

const Profile = () => {
    const theme = useTheme();
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const user = useSelector(state => state.persisted.global.currentUser).data
    // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
    const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext()
    const [
        updateUser, 
        { isLoading: isUpdating }, 
      ] = useUpdateUserMutation() 

    const initialValues = useMemo(() => ({
        firstName: user && user.firstName ? user.firstName : '',
        lastName: user && user.lastName ? user.lastName : '',
        username: user && user.username ? user.username : '',
        email: user && user.email ? user.email : '',
        phone: user && user.phone ? user.phone : '',
    }), [user?.firstName, user?.lastName, user?.username, user?.email, user?.phone])

    const checkoutSchema = yup.object().shape({
        firstName: yup.string().required('error.required'),
        lastName: yup.string().required("error.required"),
        username: yup.string().required("error.required"),
        email: yup.string().email("error.invalid.email").required("error.required"),
        phone: yup
        .string()
        .matches(REGEX_PHONE, "error.invalid.phone")
        .required("error.required"),
    });

    const handleFormSubmit = async (values) => {
        let submitObj = {
          username: values.username,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
        }
        // console.log('submitObj', submitObj)
        try {
          if (window.confirm(intl.formatMessage({id: 'window.ready.submit'})) === true) {
            setShowBackdrop(true)
            const params = {
                id: user?._id,
                token: user?.accessToken,
                body: submitObj,
              }

            const userInfo = await updateUser({
              id: user?._id,
              token: user?.accessToken,
              body: submitObj,
            })
            setShowBackdrop(false)
            // console.log('userInfo', userInfo)
            if (userInfo?.error) {
                setAlertObj({
                    severity: SEVERITY_WARNING,
                    content: intl.formatMessage({id: 'window.operation.failed'}),
                    duration: null,
                })
                setShowAlert(true)
            } else {
                setAlertObj({
                    severity: SEVERITY_SUCCESS,
                    content: intl.formatMessage({id: 'window.operation.successful'}),
                    duration: null,
                })
                setShowAlert(true)
                dispatch(setUser({
                    data: {
                        ...userInfo.data,
                        accessToken: user?.accessToken,
                    } 
                }))
                navigate('/')
            }
          }
        } catch (err) {
            console.log('err', err)
            setShowBackdrop(false)
            setAlertObj({
                severity: SEVERITY_WARNING,
                content: intl.formatMessage({id: 'window.operation.failed'}),
                duration: null,
            })
            setShowAlert(true)
            // window.alert(intl.formatMessage({id: 'window.operation.failed'}))
        }
      }
  return (
        <Stack width='100%' display='flex' justifyContent='center' alignItems='center' boxSizing={'border-box'}>
            <Stack width='100%' display='flex' justifyContent='center' alignItems='center' p={2} pt={5} boxSizing={'border-box'}>
            <Box mb={3}>
                <Typography variant='h4'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                    >
                        <FormattedMessage id='auth.profile' />
                </Typography>
            </Box>
            <Box width={mobile ? '100%' : (tablet ? '60%' : '40%')}>
                <Formik
                    onSubmit={handleFormSubmit}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                    style={{border: '1px solid red'}}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack alignItems='flex-start' width='100%'>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={intl.formatMessage({id: 'auth.first.name'})}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName && intl.formatMessage({id: errors.firstName}) }
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={intl.formatMessage({id: 'auth.last.name'})}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName && intl.formatMessage({id: errors.lastName})}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={intl.formatMessage({id: 'auth.username'})}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                name="username"
                                error={!!touched.username && !!errors.username}
                                helperText={touched.username && errors.username && intl.formatMessage({id: errors.username})}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={intl.formatMessage({id: 'auth.email.address'})}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email && intl.formatMessage({id: errors.email})}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={intl.formatMessage({id: 'auth.phone'})}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone && intl.formatMessage({id: errors.phone})}
                                sx={{mb: 3}}
                            />
                            <Button
                                disabled={
                                    !!values.email === false || 
                                    !!values.firstName === false || 
                                    !!values.lastName === false || 
                                    !!values.username === false || 
                                    !!values.phone === false || 
                                    !!errors.email || 
                                    !!errors.firstName || 
                                    !!errors.lastName || 
                                    !!errors.username || 
                                    !!errors.phone || 
                                    isUpdating }
                                sx={{
                                    width: '100%',
                                    backgroundColor: Colors.green_800,
                                    color: Colors.white,
                                    // color: theme.palette.background.alt,
                                    pt: 1.5,
                                    pb: 1.5,
                                    '&:hover': {
                                        backgroundColor: Colors.green_600,
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} type="submit"><FormattedMessage id='general.update' /></Button>
                        </Stack>
                    </form>
                    )}
                </Formik>
            </Box>
        </Stack>
    </Stack>
  )
}

export default Profile