import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/bars/navbar/Navbar";
import Menubar from "../../components/bars/menubar/Menubar";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../styles/theme";
import CartItem from "../../components/cartItem";
import { TypographyAdobe } from "../../components/TypographyAdobe";
import { setCart, setUnpaidOrder } from "../../redux/global";
import useDialogModal from "../../hooks/useDialogModal";
import Login from "../../components/modals/Login";
import { EMPTY_CART, SEVERITY_WARNING, TEXTAREA_MAX_LENGTH } from "../../constants";
import {
  useAddOrderMutation,
  useUpdateOrderByIdMutation,
} from "../../apis/order";
import { useUIContext } from "../../context/ui";
import { useGetSoldoutProductsQuery } from "../../apis/product";

const Cart = () => {
  const theme = useTheme();
  const intl = useIntl();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const cart = useSelector((state) => state.persisted.global.cart);
  const unpaidOrder = useSelector(
    (state) => state.persisted.global.unpaidOrder
  )?.data;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const products = useSelector((state) => state.product.products);
  const user = useSelector((state) => state.persisted.global.currentUser)?.data;
  const [LoginDialog, showLoginDialog, closeLoginDialog] =
    useDialogModal(Login);
  // const [AlertComponent, showAlert, closeAlert] = useAlert()
  // const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop()
  const { setShowBackdrop, setShowAlert, setAlertObj } = useUIContext();
  const [notes, setNotes] = useState("");
  const [soldouts, setSoldouts] = useState([])
  const { data: dataSoldouts, isLoading: isLoadingSoldouts } = useGetSoldoutProductsQuery({
    page: 0,
    pageSize: process.env.REACT_APP_DEFAULT_PRODUCTS_LOAD,
    vendor: process.env.REACT_APP_DEFAULT_VENDOR,
    location: process.env.REACT_APP_DEFAULT_MARKET,
  });

  // console.log('cart', cart)
  useEffect(() => {
    if (dataSoldouts?.products?.length > 0) {
      setSoldouts(dataSoldouts?.products)
    }
  }, [dataSoldouts])

  const soldoutFound = useMemo(() => {
    return soldouts?.length > 0 && soldouts.find((item) => {
      return cart?.products?.findIndex((product) => product._id === item._id) >= 0
    })
  }, [cart?.products, soldouts])

  // console.log('cart', cart)
  // console.log('soldoutFound', soldoutFound)
  // console.log('cart?.products', cart?.products)
  const [addOrder, { isLoading: isAddingOrder }] = useAddOrderMutation();

  const [updateOrderById, { isLoading: isUpdatingOrder }] =
    useUpdateOrderByIdMutation();

  const clearCart = () => {
    dispatch(
      setCart(EMPTY_CART)
    );

    dispatch(setUnpaidOrder(null));
  };

  useEffect(() => {
    if (unpaidOrder) {
        setNotes(unpaidOrder?.notes)
    }
}, [unpaidOrder])

  const cartChanged = () => {
    let changed =
      !unpaidOrder ||
      unpaidOrder?.products?.length !== cart?.products?.length ||
      false;

    if (!changed) {
      for (let i = 0; i < cart?.products?.length; i++) {
        const cartProduct = cart?.products[i];
        const orderProduct = unpaidOrder?.products.find(
          (item) => item._id === cartProduct._id
        );
        changed =
          cartProduct?.quantity !== orderProduct?.quantity ||
          cartProduct?.level !== orderProduct?.level ||
          cartProduct?.price !== orderProduct?.price ||
          cartProduct?.special !== orderProduct?.special;
        if (changed) break;
      }
    }

    return changed;
  };
  const handleCheckout = async () => {
    if (user) {
      if (soldoutFound) {
        setAlertObj({
            severity: SEVERITY_WARNING,
            content: intl.formatMessage({id: 'commercial.sold.out.cart'}),
            duration: null,
        })
        setShowAlert(true)
      } else {
        setAlertObj({
            severity: SEVERITY_WARNING,
            content: intl.formatMessage({id: 'warning.online.soon'}),
            duration: null,
        })
        setShowAlert(true)
      }
      // try {
      //   if (soldoutFound) {
      //     setAlertObj({
      //         severity: SEVERITY_WARNING,
      //         content: intl.formatMessage({id: 'commercial.sold.out.cart'}),
      //         duration: null,
      //     })
      //     setShowAlert(true)
      //   } else if (cartChanged() === false) {
      //     navigate("/checkout");
      //   } else {
      //     let submitObj = {
      //       user: user?._id,
      //       vendor: process.env.REACT_APP_DEFAULT_VENDOR,
      //       client: process.env.REACT_APP_CURRENT_CLIENT,
      //       shipping: "pickup",
      //       address: process.env.REACT_APP_DEFAULT_PICKUP_LOCATION,
      //       products: [],
      //       notes: notes,
      //       city: process.env.REACT_APP_DEFAULT_MARKET,
      //     };
      //     cart?.products.forEach((item) => {
      //       submitObj.products.push({
      //         ...item,
      //       });
      //     });
      //     // console.log('submitObj', submitObj)
      //     setShowBackdrop(true);
      //     const orderInfo = !unpaidOrder
      //       ? await addOrder({
      //           token: user?.accessToken,
      //           body: submitObj,
      //         })
      //       : await updateOrderById({
      //           id: unpaidOrder?._id,
      //           token: user?.accessToken,
      //           body: submitObj,
      //         });
      //     setShowBackdrop(false);
      //     if (orderInfo.error) {
      //       setAlertObj({
      //         severity: SEVERITY_WARNING,
      //         content: intl.formatMessage({ id: "shop.order.creation.failed" }),
      //         duration: null,
      //       });
      //       setShowAlert(true);
      //     } else {
      //       navigate("/checkout");
      //       dispatch(setUnpaidOrder(orderInfo));
      //     }
      //   }
      // } catch (err) {
      //   setShowBackdrop(false);
      //   setAlertObj({
      //     severity: SEVERITY_WARNING,
      //     content: intl.formatMessage({ id: "shop.order.creation.failed" }),
      //     duration: null,
      //   });
      //   setShowAlert(true);
      // }
    } else {
      showLoginDialog();
    }
  };
  return (
    <Stack
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={mobile ? "90%" : tablet ? "60%" : "30%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={5}
        borderBottom={`2px solid ${Colors.green_700}`}
      >
        <Typography
          variant="h4"
          color={Colors.green_800}
          fontWeight="bold"
          textTransform="uppercase"
        >
          <FormattedMessage id="shop.my.cart" />
        </Typography>
      </Box>
      {/* Cart */}
      <Stack
        justifyContent="center"
        alignItems="center"
        width={mobile ? "90%" : tablet ? "60%" : "50%"}
        pb={5}
      >
        {cart?.products?.map((cartProduct) => {
          const cartProductId = cartProduct?._id?.indexOf('-') > 0 ? cartProduct?._id?.split('-')[0] : cartProduct?._id
          const product = products?.find((item) => item._id === cartProductId);
          return (
            <CartItem
              key={cartProduct._id}
              cartProduct={cartProduct}
              product={product}
              soldouts={soldouts}
            />
          );
        })}
        {cart?.products?.length === 0 && (
          <TypographyAdobe
            variant="h4"
            p={10}
            sx={{ borderBottom: `1px solid ${Colors.gray_300}` }}
          >
            <FormattedMessage id="shop.empty.cart" />
          </TypographyAdobe>
        )}
        <Stack
          width={mobile ? "90%" : "60%"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          mt={5}
        >
          <Typography
            variant="h5"
            color={Colors.green_800}
            fontWeight="bold"
            sx={{
              mr: "1",
              borderBottom: `1px solid ${Colors.teal_800}`,
              minWidth: "300px",
              textAlign: "center",
              mb: 2,
            }}
          >
            <FormattedMessage id="shop.order.notes" />
          </Typography>

          <TextField
            id="standard-multiline-notes"
            label={`${intl.formatMessage(
              { id: "general.max.length" },
              { maxLength: TEXTAREA_MAX_LENGTH }
            )}`}
            multiline
            rows={3}
            // defaultValue=""
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            variant="standard"
            inputProps={{
              style: { fontSize: "1.1em" },
              maxLength: TEXTAREA_MAX_LENGTH,
            }}
            sx={{ mb: 1, width: "100%" }}
          />
        </Stack>
        <Box
          width={mobile ? "100%" : '80%'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box flex={1} sx={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              sx={{ width: "150px", mt: 2 }}
              onClick={() => clearCart()}
            >
              <FormattedMessage id="shop.clear.cart" />
            </Button>
          </Box>
          <Box flex={1} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ width: "150px", mt: 2 }}
              onClick={() => handleCheckout()}
            >
              <FormattedMessage id="shop.checkout" />
            </Button>
          </Box>
        </Box>
      </Stack>

      <LoginDialog mobile={mobile} tablet={tablet} setAlertObj={setAlertObj} />
    </Stack>
  );
};

export default Cart;
