import { Box, Container, Stack, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/bars/navbar/Navbar'
import Menubar from '../components/bars/menubar/Menubar'
import Footer from '../components/footer/Footer'
import { useTheme } from '@emotion/react'
import { Colors } from '../styles/theme'
import useAlert from '../hooks/useAlert'
import useBackdrop from '../hooks/useBackdrop'
import SearchBox from '../components/search/Search'
import { useUIContext } from '../context/ui'

const Layout = () => {
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { setSearchText } = useUIContext()
    const [AlertComponent] = useAlert()
    const [BackdropComponent] = useBackdrop()
    return (
        <Container
            disableGutters
            maxWidth="xl"
            sx={{
                background: Colors.body_bg,
                width: '100%',
                // height: '100%',
                margin: '0px',
                padding: '0px',
                overflowX: 'clip', 
                position: 'relative',
            }}
            >
            <Stack width='100%'>
                <Box width='100%' sx={{
                    position: '-webkit-sticky', /* Safari */
                    position: 'sticky',
                    top: '0',
                    zIndex: 999,
                }}>
                    <Navbar tablet={tablet} mobile={mobile} />
                    <Menubar tablet={tablet} mobile={mobile} />
                </Box>
                {/* <Outlet /> */}
                <Stack width='100%' display='flex' justifyContent='center' alignItems='center'>
                <Outlet />
                </Stack>
                <Footer mobile={mobile} tablet={tablet} />
            </Stack>
            
            <AlertComponent />
            <BackdropComponent />
            <SearchBox setSearchText={setSearchText} />
        </Container>
  )
}

export default Layout