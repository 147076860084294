import { Box, Dialog, DialogContent, IconButton, Stack } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { DefaultDialogDetails, DefaultDialogTitle, LanguageSelectButton } from '../../styles/dialog'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../styles/theme'
import { useNavigate } from 'react-router-dom'

const OrderConfirmation = ({ open, onClose }) => {
    const navigate = useNavigate()
  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth={'xs'}>
        <DefaultDialogTitle>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                <FormattedMessage id='shop.order.confirmation' />
                <IconButton sx={{color: Colors.white}} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DefaultDialogTitle>
        <DialogContent>
            <Stack sx={{
                width: '100%',
                height: '100%',
                marginTop: '30px',
            }}>
                <DefaultDialogDetails variant='p'>
                    <FormattedMessage id='shop.order.success'/>
                </DefaultDialogDetails>
                <LanguageSelectButton onClick={() => {
                    onClose()
                    navigate('/orders')
                }}>
                    <FormattedMessage id='modal.ok' />
                </LanguageSelectButton>
            </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default OrderConfirmation