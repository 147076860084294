import React, { useEffect, useState } from 'react'
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { NavbarContainer, NavbarLeftList, NavbarLeftWrapper, NavbarListItemButton, NavbarMainTitle, NavbarRightList, NavbarRightWrapper } from '../../../styles/bars'
import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { FormattedMessage, useIntl } from 'react-intl';
import { setUser } from "../../../redux/global";
import { TITLES } from '../../../i18n/titles'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../styles/theme';
import { LocationOn } from '@mui/icons-material';

const NavbarDesktop = ({user, showLanguageSelectDialog, showCitySelectDialog, showContactUsDialog, showPickupDialog, showLoginDialog}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const intl = useIntl()
    const locale = useSelector((state) => state.persisted.global.locale)
    const location = useSelector((state) => state.persisted.global.location)
    const cart = useSelector((state) => state.persisted.global.cart)
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleUsernameClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const [langTitle, setLangTitle] = useState('English')
    useEffect(() => {
        setLangTitle(TITLES[locale])
    }, [locale])

    const handleProfile = () => {
        setAnchorEl(null);
        navigate('/profile')
    }

    const handlePassword = () => {
        setAnchorEl(null);
        navigate('/password')
    }

    const handleOrders = () => {
        setAnchorEl(null);
        navigate('/orders')
    }

    const handleLogout = () => {
        if (window.confirm(intl.formatMessage({id: 'window.sure.logout'})) === true) {
            dispatch(setUser(null))
            navigate('/')
            setAnchorEl(null);
        }
    }
  return (
    <NavbarContainer>
        <NavbarLeftWrapper>
            <NavbarLeftList>
                {/* <NavbarListItemButton onClick={() => showLanguageSelectDialog()}>
                    <PublicSharpIcon sx={{marginRight: '5px'}} />
                    <Typography variant='p'>{langTitle}</Typography>
                </NavbarListItemButton>
                <Divider orientation='vertical' flexItem /> */}
                {/* <NavbarListItemButton onClick={() => showContactUsDialog()}>
                    <Typography variant='p'><FormattedMessage id='app.contact' /></Typography>
                </NavbarListItemButton> */}
                {/* <NavbarListItemButton onClick={() => showCitySelectDialog()}>
                    <LocationOn sx={{marginRight: '5px'}} />
                    <Typography variant='p' sx={{textTransform: 'capitalize'}}>{location}</Typography>
                </NavbarListItemButton>
                <Divider orientation='vertical' flexItem /> */}
                <NavbarListItemButton onClick={() => showPickupDialog()}>
                    <Typography variant='p'><FormattedMessage id='app.pickup' /></Typography>
                </NavbarListItemButton>
            </NavbarLeftList>
        </NavbarLeftWrapper>
        
        <NavbarMainTitle variant='h5' onClick={() => navigate('/')} sx={{
            '&:hover': {
                cursor: 'pointer',
            }
        }}>
            The Honest Fresh Produce 
        </NavbarMainTitle>
        <NavbarRightWrapper>
            <NavbarRightList>
                {
                    !user && (
                        <NavbarListItemButton case='uppercase' onClick={() => showLoginDialog()}>
                            <Typography variant='p'>
                                <FormattedMessage id='app.login' />
                            </Typography>
                    </NavbarListItemButton>)
                }
                {
                    user && (
                        <>
                            <NavbarListItemButton case='lowercase' onClick={(e) => handleUsernameClick(e)}>
                                <Typography variant='p'>
                                    <FormattedMessage id="general.hello" />, {user.username}
                                </Typography>
                            </NavbarListItemButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            >
                                <MenuItem onClick={handleProfile}><FormattedMessage id='auth.profile' /></MenuItem>
                                <MenuItem onClick={handlePassword}><FormattedMessage id='auth.change.password' /></MenuItem>
                                <MenuItem onClick={handleOrders}><FormattedMessage id='auth.orders' /></MenuItem>
                                <MenuItem onClick={handleLogout}><FormattedMessage id='auth.logout' /></MenuItem>
                            </Menu>
                        </>)    
                }
                <NavbarListItemButton onClick={() => navigate('/cart')}>
                    <Badge badgeContent={cart?.quantity} color='warning'>
                        <ShoppingCartIcon />
                    </Badge>
                </NavbarListItemButton>
            </NavbarRightList>
        </NavbarRightWrapper>
    </NavbarContainer>
  )
}

export default NavbarDesktop