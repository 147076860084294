import React from 'react'
import MenubarMobile from './MenubarMobile';
import MenubarDesktop from './MenubarDesktop';

const Menubar = ({tablet, mobile}) => {
    return (
        <>
            {(!mobile) && 
                (<MenubarDesktop />)
            }
        </>
    )
}

export default Menubar